import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthenticatedAgentService } from './authenticated-agent.service';
import { EnhancedAnalyticsService } from './enhanced-analytics.service';
import { intersection, union, has, get, find, some, isEqual, sortBy } from '../../lib/lodash';
import { ClientRoles, Features } from '../enums';

@Injectable({
    providedIn: 'root'
})
export class RoleAuthorizationService {

    internalRoleList = [
        'ROLE_RootAdmin',
        'ROLE_TranslationAdmin',
        'ROLE_RoleManager',
        'ROLE_ReportingAdmin',
        'ROLE_SubmitMembersRewards',
        'ROLE_CalendarAdmin',
        'ROLE_ClientSupportAgent',
        'ROLE_EligibilityAdmin',
        'ROLE_CommunicationsAdmin',
        'ROLE_Security'
    ];

    externalRoleList = [
        'ROLE_ReportingSponsorAdmin',
        'ROLE_ReportingViewer',
        'ROLE_CalendarSponsorAdmin',
        'ROLE_SponsorSuperAdministrator',
        'ROLE_SponsorAdministrator',
        'ROLE_SponsorViewer',
        'ROLE_SponsorCalendarViewer',
        'ROLE_ChallengeAdmin',
        'ROLE_ChallengeAdminWithoutPII',
        'ROLE_ChallengeViewer',
        'ROLE_Champion',
        'ROLE_SurveyAdmin',
        'ROLE_ShoutoutsAdmin',
        'ROLE_SurveyViewer',
        'ROLE_HabitsViewer',
        'ROLE_HabitsAdmin',
        'ROLE_CoachAdmin',
        'ROLE_CardAdmin',
        'ROLE_CardViewer',
        'ROLE_ReportArchiveViewer',
        'ROLE_AgentAdmin_BasicInfoPage',
        'ROLE_AgentAdmin_RedemptionPage',
        'ROLE_AgentAdmin_RewardsPage',
        'ROLE_AgentAdmin_RewardableActionPage',
        'ROLE_AgentAdmin_SubmitRedemptionTransaction',
        'ROLE_AgentAdmin_TransactionCredentialsViewer',
        'ROLE_AgentAdmin_ViewPHI',
        'ROLE_AgentAdmin_MemberEligible',
        'ROLE_AgentAdmin_RewardsViewOnly',
        'ROLE_AgentManager',
        'ROLE_PlatformAnalyticsAdministrator',
        'ROLE_ClaimsDashboardAdmin',
        'ROLE_NavigateDashboardAdmin',
        'ROLE_ExtractCatalogAdmin',
        'ROLE_PepsiCoChallengeDashboardAdmin',
        'ROLE_TransformDashboardAdmin',
        'ROLE_PopulationRiskDashboardAdmin',
        'ROLE_PartnerReportingAdministrator',
        'ROLE_DataHubViewer',
        'ROLE_EmailCampaignAdmin',
        'ROLE_ShoutoutsAdmin',
        'ROLE_HealthStationAdmin',
        'ROLE_BenefitsClientAdmin',
        'ROLE_BrandingLogoAdmin',
        'ROLE_BrandingColorsAdmin',
        'ROLE_TPAAdminGroupEnrollment',
        'ROLE_TPASuperAdmin',
        'ROLE_TPAAdminClaimsEOB',
        'ROLE_TPABrokerAdminPHI',
        'ROLE_TPABrokerAdminNoPHI',
        'ROLE_HNAdmin'
    ];

    domoChecked = false;
    hasDomo = false;

    CORE_SPONSOR_MEMBER = 'Core';
    CONTENT = 'content';
    SPONSOR_SELECT = 'sponsorSelect';
    CONTENT_ACCESS_ROLE_ALL_SPONSORS = 'AllSponsors';
    CONTENT_ACCESS_ROLE_SPONSOR = 'Sponsor';
    roleList = union(this.internalRoleList, this.externalRoleList);
    roles = this.createMapFromList(this.roleList);
    featureList = [
        'ChallengeClientAdmin',
        'PillarsAndTopicsAdminToolHealthPremium',
        'PillarsAndTopicsAdminToolWork',
        'AudienceAdmin',
        'BetaTesting',
        'BrandingColors',
        'BrandingLogo'
    ];
    contentRolesExcludingSponsorAdmin = [
        this.roles.ROLE_RootAdmin,
        this.roles.ROLE_SponsorSuperAdministrator,
        this.roles.ROLE_ClientSupportAgent,
        this.roles.ROLE_SponsorViewer
    ];

    features = this.createMapFromList(this.featureList);
    pages: any;
    actions: any;

    setPages() {
        this.pages = {
            analyticusers: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_ReportingAdmin,
                    this.roles.ROLE_ReportingSponsorAdmin
                ]
            },
            benefits: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_SponsorViewer,
                    this.roles.ROLE_BenefitsClientAdmin
                ]
            },
            tpa_resources: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_TPAAdminGroupEnrollment,
                    this.roles.ROLE_TPASuperAdmin,
                    this.roles.ROLE_TPABrokerAdminNoPHI
                ]
            },
            calendar: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_CalendarAdmin,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_SponsorViewer,
                    this.roles.ROLE_CalendarSponsorAdmin,
                    this.roles.ROLE_SponsorCalendarViewer
                ]
            },
            challenges: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_ChallengeAdmin,
                    this.roles.ROLE_ChallengeAdminWithoutPII,
                    this.roles.ROLE_ChallengeViewer,
                    this.roles.ROLE_SponsorViewer,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_ReportingAdmin
                ],
                features: [
                    this.features.ChallengeClientAdmin
                ]
            },
            surveys: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_SurveyAdmin,
                    this.roles.ROLE_SurveyViewer,
                    this.roles.ROLE_SponsorViewer,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_ReportingAdmin
                ],
                features: [
                    Features.SurveyAdminLite,
                    Features.SurveyAdminPremium
                ],
                rolesBypass: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_ClientSupportAgent
                ]
            },
            habits: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_HabitsViewer,
                    this.roles.ROLE_HabitsAdmin,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_SponsorViewer
                ]
            },
            announcement: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_CardAdmin,
                    this.roles.ROLE_HabitsAdmin,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_CardViewer,
                    this.roles.ROLE_HabitsViewer
                ]
            },
            content: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_SponsorViewer,
                    this.roles.ROLE_CardAdmin,
                    this.roles.ROLE_CardViewer,
                    this.roles.ROLE_SponsorViewer,
                    this.roles.ROLE_HabitsViewer,
                    this.roles.ROLE_HabitsAdmin,
                    this.roles.ROLE_EmailCampaignAdmin
                ]
            },
            pillars: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_SponsorViewer
                ],
                features: [
                    this.features.PillarsAndTopicsAdminToolWork,
                    this.features.PillarsAndTopicsAdminToolHealthPremium
                ],
                rolesBypass: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_ClientSupportAgent
                ]
            },
            recognitions: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_RoleManager,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_ShoutoutsAdmin
                ]
            },
            topic: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_SponsorViewer
                ]
            },
            permissions: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_RoleManager,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_ReportingAdmin,
                    this.roles.ROLE_ReportingSponsorAdmin
                ]
            },
            provisioning: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_SponsorSuperAdministrator
                ]
            },
            distributor: {
                roles: [
                    this.roles.ROLE_RootAdmin
                ]
            },
            reporting: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_ReportingViewer,
                    this.roles.ROLE_ReportingAdmin,
                    this.roles.ROLE_ReportingSponsorAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator
                ]
            },
            enrollmentEngagement: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_ReportingViewer,
                    this.roles.ROLE_ReportingAdmin,
                    this.roles.ROLE_ReportingSponsorAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator
                ]
            },
            reportArchive: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_ReportingViewer,
                    this.roles.ROLE_ReportingAdmin,
                    this.roles.ROLE_ReportingSponsorAdmin,
                    this.roles.ROLE_ReportArchiveViewer,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_ClientSupportAgent
                ]
            },
            resourceCenter: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_ReportingAdmin,
                    this.roles.ROLE_ReportingSponsorAdmin,
                    this.roles.ROLE_CalendarAdmin,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_CalendarSponsorAdmin
                ]
            },
            systemContent: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_SponsorViewer
                ],
                features: [
                    this.features.PillarsAndTopicsAdminToolWork,
                    this.features.PillarsAndTopicsAdminToolHealthPremium
                ],
                rolesBypass: [
                    this.roles.ROLE_RootAdmin
                ]
            },
            sponsorSelect: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_CalendarAdmin,
                    this.roles.ROLE_RoleManager,
                    this.roles.ROLE_ReportingAdmin,
                    this.roles.ROLE_ReportingViewer,
                    this.roles.ROLE_ClientSupportAgent
                ]
            },
            promotedHabit: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_ChallengeAdmin,
                    this.roles.ROLE_ChallengeAdminWithoutPII,
                    this.roles.ROLE_ChallengeViewer,
                    this.roles.ROLE_SponsorViewer,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_ReportingAdmin
                ]
            },
            audiences: {
                roles: [
                    this.roles.ROLE_RootAdmin
                ],
                features: [
                    Features.AudienceAdmin
                ]
            },
            coaching: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_CoachAdmin,
                    this.roles.ROLE_ClientSupportAgent
                ],
                features: [
                    Features.Coaching
                ]
            },
            cards: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_SponsorAdministrator,
                    this.roles.ROLE_CardAdmin,
                    this.roles.ROLE_CardViewer,
                    this.roles.ROLE_SponsorViewer,
                    this.roles.ROLE_ClientSupportAgent
                ]
            },
            downloads: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_RoleManager,
                    this.roles.ROLE_ClientSupportAgent,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_ReportingAdmin,
                    this.roles.ROLE_ReportingSponsorAdmin
                ]
            },
            agentAdmin: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_AgentAdmin_BasicInfoPage,
                    this.roles.ROLE_TPASuperAdmin,
                    this.roles.ROLE_TPAAdminClaimsEOB,
                    this.roles.ROLE_TPABrokerAdminPHI
                ]
            },
            agentAdminEligibles: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_AgentAdmin_MemberEligible
                ]
            },
            emailCampaigns: {
                roles: [
                    this.roles.ROLE_EmailCampaignAdmin
                ]
            },
            agentManagement: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_AgentManager,
                    this.roles.ROLE_Security
                ]
            },
            enhancedAnalytics: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_PlatformAnalyticsAdministrator,
                    this.roles.ROLE_ClaimsDashboardAdmin,
                    this.roles.ROLE_PartnerReportingAdministrator,
                    this.roles.ROLE_ExtractCatalogAdmin,
                    this.roles.ROLE_NavigateDashboardAdmin,
                    this.roles.ROLE_PepsiCoChallengeDashboardAdmin,
                    this.roles.ROLE_TransformDashboardAdmin,
                    this.roles.ROLE_PopulationRiskDashboardAdmin
                ]
            },
            sponsorPerformance: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_PlatformAnalyticsAdministrator,
                    this.roles.ROLE_SponsorSuperAdministrator
                ]
            },
            claimsDashboard: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_ClaimsDashboardAdmin
                ]
            },
            extractCatalog: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_ExtractCatalogAdmin,
                    this.roles.ROLE_PlatformAnalyticsAdministrator,
                    this.roles.ROLE_SponsorSuperAdministrator
                ]
            },
            pepsiCoChallengeDashboard: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_PepsiCoChallengeDashboardAdmin
                ]
            },
            populationRiskDashboard: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_PopulationRiskDashboardAdmin
                ]
            },
            transformDashboard: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_TransformDashboardAdmin
                ]
            },
            partners: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_PartnerReportingAdministrator
                ]
            },
            microstrategy: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_PlatformAnalyticsAdministrator
                ]
            },
            dataHub: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_DataHubViewer
                ]
            },
            branding: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_BrandingLogoAdmin,
                    this.roles.ROLE_BrandingColorsAdmin
                ],
                features: [
                    this.features.BrandingColors,
                    this.features.BrandingLogo
                ]
            },
            colors: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_BrandingColorsAdmin
                ],
                features: [
                    this.features.BrandingColors
                ]
            },
            logo: {
                roles: [
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_SponsorSuperAdministrator,
                    this.roles.ROLE_BrandingLogoAdmin
                ],
                features: [
                    this.features.BrandingLogo
                ]
            },
            idCardRequests: {
                roles: [
                    this.roles.ROLE_TPAAdminClaimsEOB
                ]
            },
            resourcesMenu: {
                roles: [
                    this.roles.ROLE_TPAAdminClaimsEOB,
                    this.roles.ROLE_RootAdmin,
                    this.roles.ROLE_TPAAdminGroupEnrollment,
                    this.roles.ROLE_TPASuperAdmin,
                    this.roles.ROLE_TPABrokerAdminNoPHI
                ]
            },
            health_notes: {
                roles: [
                    this.roles.ROLE_HNAdmin
                ]
            }
        };

        this.pages['manage-role'] = this.pages.provisioning;
        this.pages['manage-users'] = this.pages.provisioning;
        this.pages['user-roles'] = this.pages.provisioning;
        this.pages['analyticusers.list'] = this.pages.analyticusers;
        this.pages['analyticusers.sponsor'] = this.pages.analyticusers;
        this.pages['challenge.new'] = this.pages.challenges;
        this.pages['challenge.id'] = this.pages.challenges;
        this.pages['challenge'] = this.pages.challenges;
        this.pages['challenge.results'] = this.pages.challenges;
        this.pages['challenge.permissioning'] = this.pages.challenges;

        this.pages['benefits-mfe'] = this.pages.benefits;
        this.pages['benefits-client'] = this.pages.benefits;
        this.pages['benefits-client/benefit-program'] = this.pages.benefits;
        this.pages['benefits-client/custom-pages'] = this.pages.benefits;
        this.pages['resources'] = this.pages.resourcesMenu;
        this.pages['tpa-resources'] = this.pages.tpa_resources;
        this.pages['tpa-resources/tpa-resources'] = this.pages.tpa_resources;
        this.pages['tpa-resources/authorization-page'] = this.pages.tpa_resources;
        this.pages['tpa-resources/enrollment-benefits'] = this.pages.tpa_resources;
        this.pages['tpa-resources/coverage'] = this.pages.tpa_resources;
        this.pages['tpa-resources/additional-information'] = this.pages.tpa_resources;
        this.pages['tpa-resources/resource-information'] = this.pages.tpa_resources;
        this.pages['tpa-resources/id-card-requests'] = this.pages.idCardRequests;
        this.pages['challenges-mfe'] = this.pages.challenges;
        this.pages['challenges-mfe.results'] = this.pages.challenges;
        this.pages['challenges-mfe.permissioning'] = this.pages.challenges;
        this.pages['challenges-mfe.goal-challenge-results'] = this.pages.challenges;
        this.pages['challenges-mfe.new'] = this.pages.challenges;

        this.pages['goalChallenge.results'] = this.pages.challenges;
        this.pages['goalChallenge.new'] = this.pages.challenges;
        this.pages['goalChallenge.id'] = this.pages.challenges;
        this.pages['goalChallenge'] = this.pages.challenges;

        this.pages['survey.new'] = this.pages.surveys;
        this.pages['survey.i18n'] = this.pages.surveys;
        this.pages['survey.id'] = this.pages.surveys;
        this.pages['survey.results'] = this.pages.surveys;
        this.pages['survey.recurrence'] = this.pages.surveys;
        this.pages['survey.permissioning'] = this.pages.surveys;
        this.pages['survey'] = this.pages.surveys;

        this.pages['habit'] = this.pages.habits;
        this.pages['habit-new'] = this.pages.habits;
        this.pages['habit-edit'] = this.pages.habits;

        this.pages['announcement.list'] = this.pages.announcement;
        this.pages['announcement.id'] = this.pages.announcement;
        this.pages['announcement.new'] = this.pages.announcement;

        this.pages['pillar'] = this.pages.pillars;
        this.pages['pillar.new'] = this.pages.pillars;
        this.pages['pillar.edit'] = this.pages.pillars;
        this.pages['pillarSettings'] = this.pages.pillars;

        // this.pages['topic'] = this.pages.topic;
        this.pages['topic.new'] = this.pages.topic;
        this.pages['topic.edit'] = this.pages.topic;

        // this.pages['systemContent'] = this.pages.systemContent;
        this.pages['systemContent.list'] = this.pages.systemContent;
        this.pages['systemContent.pendingList'] = this.pages.systemContent;
        this.pages['systemContent.tracker'] = this.pages.systemContent;
        this.pages['systemContent.widget'] = this.pages.systemContent;

        // this.pages['calendar'] = this.pages.calendar;
        this.pages['calendarEvent.new'] = this.pages.calendar;
        this.pages['calendarEvent.id'] = this.pages.calendar;
        this.pages['calendar.home'] = this.pages.calendar;

        this.pages['promotedHabit.new'] = this.pages.promotedHabit;
        this.pages['promotedHabit.id'] = this.pages.promotedHabit;
        // this.pages['promotedHabit'] = this.pages.promotedHabit;

        this.pages['promotions'] = this.pages.coaching;

        this.pages['card'] = this.pages.cards;
        this.pages['card-new'] = this.pages.cards;
        this.pages['card-id'] = this.pages.cards;

        // this.pages['downloads'] = this.pages.downloads;

        this.pages['agent-admin'] = this.pages.agentAdmin;
        this.pages['agent-admin-members'] = this.pages.agentAdmin;

        this.pages['agent-admin-eligibles'] = this.pages.agentAdminEligibles;

        this.pages['agent-management'] = this.pages.agentManagement;
        this.pages['agent-management-edit'] = this.pages.agentManagement;
        this.pages['agent-management-lock'] = this.pages.agentManagement;

        this.pages['enhanced-analytics'] = this.pages.enhancedAnalytics;
        this.pages['sponsor-performance'] = this.pages.sponsorPerformance;
        this.pages['sponsor-performance-child'] = this.pages.sponsorPerformance;
        this.pages['sponsor-performance-grandchild'] = this.pages.sponsorPerformance;
        this.pages['program-performance'] = this.pages.sponsorPerformance;
        this.pages['population-health'] = this.pages.sponsorPerformance;
        this.pages['point-solutions'] = this.pages.sponsorPerformance;
        this.pages['engage-enroll'] = this.pages.sponsorPerformance;
        this.pages['incentives'] = this.pages.sponsorPerformance;
        this.pages['incentives-child'] = this.pages.sponsorPerformance;
        this.pages['login-activity'] = this.pages.sponsorPerformance;
        this.pages['activity-tracking'] = this.pages.sponsorPerformance;
        this.pages['activity-tracking-child'] = this.pages.sponsorPerformance;
        this.pages['holistic-challenges'] = this.pages.sponsorPerformance;
        this.pages['health-check'] = this.pages.sponsorPerformance;
        this.pages['biometrics'] = this.pages.sponsorPerformance;
        this.pages['gaps-in-care'] = this.pages.sponsorPerformance;
        this.pages['gaps-in-care-child'] = this.pages.sponsorPerformance;
        this.pages['partners'] = this.pages.sponsorPerformance;
        this.pages['partners-child'] = this.pages.sponsorPerformance;
        this.pages['coaching-dashboard'] = this.pages.sponsorPerformance;
        this.pages['coaching-dashboard-child'] = this.pages.sponsorPerformance;
        this.pages['claims-dashboard'] = this.pages.claimsDashboard;
        this.pages['claims-dashboard-child'] = this.pages.claimsDashboard;
        this.pages['population-risk'] = this.pages.populationRiskDashboard;
        this.pages['transform'] = this.pages.transformDashboard;
        this.pages['extract-catalog'] = this.pages.extractCatalog;
        this.pages['extract-catalog-child'] = this.pages.extractCatalog;
        this.pages['extract-catalog-grandchild'] = this.pages.extractCatalog;
        this.pages['pepsico'] = this.pages.pepsiCoChallengeDashboard;
        this.pages['pepsico-child'] = this.pages.pepsiCoChallengeDashboard;
        // this.pages['partners'] = this.pages.partners;
        // this.pages['microstrategy'] = this.pages.microstrategy;
        this.pages['microstrategy-child'] = this.pages.microstrategy;

        this.pages['data-hub'] = this.pages.dataHub;
        this.pages['data-hub-child'] = this.pages.dataHub;
        this.pages['data-hub-grandchild'] = this.pages.dataHub;

        this.pages['email-campaigns'] = this.pages.emailCampaigns;
        this.pages['email-templates'] = this.pages.emailCampaigns;
        this.pages['email-create-template'] = this.pages.emailCampaigns;
        this.pages['email-edit-template'] = this.pages.emailCampaigns;
        this.pages['email-create-campaign'] = this.pages.emailCampaigns;
        this.pages['email-edit-campaign'] = this.pages.emailCampaigns;
        this.pages['email-campaign'] = this.pages.emailCampaigns;

        // this.pages['recognitions'] = this.pages.recognitions;

        this.pages['resource-center'] = this.pages.resourceCenter;

        this.pages['logo-new'] = this.pages.logo;
        this.pages['logo-edit'] = this.pages.logo;

        this.pages['colors-new'] = this.pages.colors;
        this.pages['colors-preview'] = this.pages.colors;
        this.pages['colors-edit'] = this.pages.colors;
        this.pages['health-notes'] = this.pages.health_notes;
        this.pages['health-notes.home'] = this.pages.health_notes;
        this.pages['health-notes.search'] = this.pages.health_notes;
    }

    setActions() {
        this.actions = {
            calendar: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_CalendarAdmin,
                        this.roles.ROLE_ClientSupportAgent,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_CalendarSponsorAdmin,
                        this.roles.ROLE_TranslationAdmin
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_CalendarAdmin,
                        this.roles.ROLE_ClientSupportAgent,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_CalendarSponsorAdmin,
                        this.roles.ROLE_TranslationAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator
                    ]
                }
            },
            benefits: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_BenefitsClientAdmin
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_BenefitsClientAdmin
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_SponsorViewer,
                        this.roles.ROLE_BenefitsClientAdmin
                    ]
                }
            },
            idCardRequests: {
                create: {
                    roles: [
                        this.roles.ROLE_TPAAdminClaimsEOB
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_TPAAdminClaimsEOB
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_TPAAdminClaimsEOB
                    ]
                }
            },
            tpa_resources: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_TPAAdminGroupEnrollment,
                        this.roles.ROLE_TPASuperAdmin,
                        this.roles.ROLE_TPABrokerAdminNoPHI
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_TPAAdminGroupEnrollment,
                        this.roles.ROLE_TPASuperAdmin,
                        this.roles.ROLE_TPABrokerAdminNoPHI
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_TPAAdminGroupEnrollment,
                        this.roles.ROLE_TPASuperAdmin,
                        this.roles.ROLE_TPABrokerAdminNoPHI
                    ]
                }
            },
            surveys: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_SurveyAdmin,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_SurveyAdmin,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_SurveyAdmin,
                        this.roles.ROLE_SurveyViewer,
                        this.roles.ROLE_SponsorViewer,
                        this.roles.ROLE_ClientSupportAgent,
                        this.roles.ROLE_ReportingAdmin
                    ]
                }
            },
            challenges: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_ChallengeAdmin,
                        this.roles.ROLE_ChallengeAdminWithoutPII,
                        this.roles.ROLE_ClientSupportAgent
                    ],
                    features: [
                        this.features.ChallengeClientAdmin
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_ChallengeAdmin,
                        this.roles.ROLE_ChallengeAdminWithoutPII,
                        this.roles.ROLE_ChallengeViewer,
                        this.roles.ROLE_SponsorViewer,
                        this.roles.ROLE_ReportingAdmin
                    ],
                    features: [
                        this.features.ChallengeClientAdmin
                    ]
                }
            },
            distributor: {
                create: {
                    roles: this.pages.distributor.roles
                },
                edit: {
                    roles: this.pages.distributor.roles
                },
                delete: {
                    roles: this.pages.distributor.roles
                },
                view: {
                    roles: this.pages.distributor.roles
                }
            },
            provisioning: {
                create: {
                    roles: this.pages.provisioning.roles,
                    ROLE_SponsorSuperAdministrator: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_ClientSupportAgent,
                        this.roles.ROLE_SponsorSuperAdministrator
                    ]
                },
                edit: {
                    roles: this.pages.provisioning.roles,
                    ROLE_RootAdmin: {
                        roles: [
                            this.roles.ROLE_RootAdmin
                        ]
                    },
                    ROLE_SponsorSuperAdministrator: {
                        roles: [
                            this.roles.ROLE_RootAdmin,
                            this.roles.ROLE_ClientSupportAgent,
                            this.roles.ROLE_SponsorSuperAdministrator
                        ]
                    }
                },
                delete: {
                    roles: this.pages.provisioning.roles,
                    ROLE_RootAdmin: {
                        roles: [
                            this.roles.ROLE_RoleManager
                        ]
                    },
                    ROLE_TranslationAdmin: {
                        roles: [
                            this.roles.ROLE_RoleManager
                        ]
                    },
                    ROLE_RoleManager: {
                        roles: [
                            this.roles.ROLE_RoleManager
                        ]
                    },
                    ROLE_ReportingAdmin: {
                        roles: [

                        ]
                    },
                    ROLE_SubmitMembersRewards: {
                        roles: [
                            this.roles.ROLE_RoleManager
                        ]
                    },
                    ROLE_CalendarAdmin: {
                        roles: [
                            this.roles.ROLE_RoleManager
                        ]
                    },
                    ROLE_ClientSupportAgent: {
                        roles: [
                            this.roles.ROLE_RoleManager
                        ]
                    },
                    ROLE_SponsorSuperAdministrator: {
                        roles: [
                            this.roles.ROLE_RootAdmin,
                            this.roles.ROLE_RoleManager,
                            this.roles.ROLE_ClientSupportAgent
                        ]
                    },
                    ROLE_ReportingSponsorAdmin: {
                        roles: [

                        ]
                    },
                    ROLE_ReportingViewer: {
                        roles: [
                            this.roles.ROLE_SponsorSuperAdministrator
                        ]
                    }
                },
                view: {
                    roles: this.pages.provisioning.roles,
                    ROLE_SponsorSuperAdministrator: {
                        roles: [
                            this.roles.ROLE_RootAdmin,
                            this.roles.ROLE_RoleManager,
                            this.roles.ROLE_ClientSupportAgent,
                            this.roles.ROLE_SponsorSuperAdministrator
                        ]
                    },
                    ROLE_SurveyAdmin: {
                        roles: [
                            this.roles.ROLE_RootAdmin,
                            this.roles.ROLE_RoleManager,
                            this.roles.ROLE_SponsorSuperAdministrator
                        ]
                    },
                    ROLE_SurveyViewer: {
                        roles: [
                            this.roles.ROLE_RootAdmin,
                            this.roles.ROLE_RoleManager,
                            this.roles.ROLE_SponsorSuperAdministrator
                        ]
                    },
                    ROLE_Coach: {
                        roles: [
                            this.roles.ROLE_RootAdmin,
                            this.roles.ROLE_SponsorSuperAdministrator
                        ]
                    }
                }
            },
            habits: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_HabitsAdmin
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_HabitsAdmin
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_HabitsAdmin,
                        this.roles.ROLE_HabitsViewer,
                        this.roles.ROLE_SponsorViewer
                    ]
                }
            },
            pillars: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_ClientSupportAgent,
                        this.roles.ROLE_SponsorViewer
                    ]
                }
            },
            logo: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_LogoAdmin
                    ],
                    features: [
                        this.features.BrandingLogo
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_LogoAdmin
                    ],
                    features: [
                        this.features.BrandingLogo
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_LogoAdmin
                    ],
                    features: [
                        this.features.BrandingLogo
                    ]
                }
            },
            colors: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_ColorsAdmin
                    ],
                    features: [
                        this.features.BrandingColors
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_ColorsAdmin
                    ],
                    features: [
                        this.features.BrandingColors
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_ColorsAdmin
                    ],
                    features: [
                        this.features.BrandingColors
                    ]
                }
            },
            topic: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_ClientSupportAgent,
                        this.roles.ROLE_SponsorViewer
                    ]
                }
            },
            coaching: {
                promotions: {
                    roles: this.pages.coaching.roles
                }
            },
            systemContent: {
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_HabitsViewer,
                        this.roles.ROLE_HabitsAdmin,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_HabitsViewer,
                        this.roles.ROLE_HabitsAdmin,
                        this.roles.ROLE_ClientSupportAgent,
                        this.roles.ROLE_SponsorViewer
                    ]
                }
            },
            cards: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_CardAdmin,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_CardAdmin,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_CardAdmin,
                        this.roles.ROLE_CardViewer,
                        this.roles.ROLE_SponsorViewer,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                }
            },
            announcement: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_CardAdmin,
                        this.roles.ROLE_HabitsAdmin,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_CardAdmin,
                        this.roles.ROLE_HabitsAdmin,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_CardAdmin,
                        this.roles.ROLE_CardViewer,
                        this.roles.ROLE_HabitsAdmin,
                        this.roles.ROLE_HabitsViewer,
                        this.roles.ROLE_SponsorViewer,
                        this.roles.ROLE_ClientSupportAgent
                    ]
                }
            },
            agentAdmin: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_AgentAdmin_BasicInfoPage,
                        this.roles.ROLE_AgentAdmin_RedemptionPage,
                        this.roles.ROLE_AgentAdmin_RewardsPage,
                        this.roles.ROLE_AgentAdmin_RewardableActionPage,
                        this.roles.ROLE_AgentAdmin_TransactionCredentialsViewer,
                        this.roles.ROLE_AgentAdmin_SubmitRedemptionTransaction,
                        this.roles.ROLE_AgentAdmin_ViewPHI,
                        this.roles.ROLE_AgentAdminRewardsViewOnly
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_AgentAdmin_BasicInfoPage,
                        this.roles.ROLE_AgentAdmin_RedemptionPage,
                        this.roles.ROLE_AgentAdmin_RewardsPage,
                        this.roles.ROLE_AgentAdmin_RewardableActionPage,
                        this.roles.ROLE_AgentAdmin_TransactionCredentialsViewer,
                        this.roles.ROLE_AgentAdmin_SubmitRedemptionTransaction,
                        this.roles.ROLE_AgentAdmin_ViewPHI,
                        this.roles.ROLE_AgentAdminRewardsViewOnly
                    ]
                },
                delete: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_AgentAdmin_BasicInfoPage,
                        this.roles.ROLE_AgentAdmin_RedemptionPage,
                        this.roles.ROLE_AgentAdmin_RewardsPage,
                        this.roles.ROLE_AgentAdmin_RewardableActionPage,
                        this.roles.ROLE_AgentAdmin_TransactionCredentialsViewer,
                        this.roles.ROLE_AgentAdmin_SubmitRedemptionTransaction,
                        this.roles.ROLE_AgentAdmin_ViewPHI,
                        this.roles.ROLE_AgentAdminRewardsViewOnly
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_AgentAdmin_BasicInfoPage,
                        this.roles.ROLE_AgentAdmin_RedemptionPage,
                        this.roles.ROLE_AgentAdmin_RewardsPage,
                        this.roles.ROLE_AgentAdmin_RewardableActionPage,
                        this.roles.ROLE_AgentAdmin_TransactionCredentialsViewer,
                        this.roles.ROLE_AgentAdmin_SubmitRedemptionTransaction,
                        this.roles.ROLE_AgentAdmin_ViewPHI,
                        this.roles.ROLE_AgentAdminRewardsViewOnly,
                        this.roles.ROLE_TPASuperAdmin,
                        this.roles.ROLE_TPAAdminClaimsEOB,
                        this.roles.ROLE_TPABrokerAdminPHI
                    ]
                }
            },
            agentAdminEligibles: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_AgentAdmin_MemberEligible
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_AgentAdmin_MemberEligible
                    ]
                },
                delete: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_AgentAdmin_MemberEligible
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_AgentAdmin_MemberEligible
                    ]
                }
            },
            enhancedAnalytics: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_PlatformAnalyticsAdministrator,
                        this.roles.ROLE_ClaimsDashboardAdmin,
                        this.roles.ROLE_ExtractCatalogAdmin,
                        this.roles.ROLE_ExtractCatalogNoDownload,
                        this.roles.ROLE_PepsiCoChallengeDashboardAdmin,
                        this.roles.ROLE_TransformDashboardAdmin,
                        this.roles.ROLE_PopulationRiskDashboardAdmin,
                        this.roles.ROLE_PartnerReportingAdministrator
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_PlatformAnalyticsAdministrator,
                        this.roles.ROLE_ClaimsDashboardAdmin,
                        this.roles.ROLE_ExtractCatalogAdmin,
                        this.roles.ROLE_ExtractCatalogNoDownload,
                        this.roles.ROLE_PepsiCoChallengeDashboardAdmin,
                        this.roles.ROLE_TransformDashboardAdmin,
                        this.roles.ROLE_PopulationRiskDashboardAdmin,
                        this.roles.ROLE_PartnerReportingAdministrator
                    ]
                },
                delete: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_PlatformAnalyticsAdministrator,
                        this.roles.ROLE_ClaimsDashboardAdmin,
                        this.roles.ROLE_ExtractCatalogAdmin,
                        this.roles.ROLE_ExtractCatalogNoDownload,
                        this.roles.ROLE_PepsiCoChallengeDashboardAdmin,
                        this.roles.ROLE_TransformDashboardAdmin,
                        this.roles.ROLE_PopulationRiskDashboardAdmin,
                        this.roles.ROLE_PartnerReportingAdministrator
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_PlatformAnalyticsAdministrator,
                        this.roles.ROLE_ClaimsDashboardAdmin,
                        this.roles.ROLE_ExtractCatalogAdmin,
                        this.roles.ROLE_ExtractCatalogNoDownload,
                        this.roles.ROLE_PepsiCoChallengeDashboardAdmin,
                        this.roles.ROLE_TransformDashboardAdmin,
                        this.roles.ROLE_PopulationRiskDashboardAdmin,
                        this.roles.ROLE_PartnerReportingAdministrator
                    ]
                }
            },
            dataHub: {
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_DataHubViewer
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_DataHubViewer
                    ]
                },
                delete: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_DataHubViewer
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_DataHubViewer
                    ]
                }
            },
            emailCampaigns: {
                create: {
                    roles: [
                        this.roles.ROLE_EmailCampaignAdmin
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_EmailCampaignAdmin
                    ]
                },
                delete: {
                    roles: [
                        this.roles.ROLE_EmailCampaignAdmin
                    ]
                },
                view: {
                    roles: [
                        this.roles.ROLE_EmailCampaignAdmin
                    ]
                }
            },
            recognitions: {
                view: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_RoleManager,
                        this.roles.ROLE_ClientSupportAgent,
                        this.roles.ROLE_ShoutoutsAdmin
                    ]
                },
                create: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_RoleManager,
                        this.roles.ROLE_ClientSupportAgent,
                        this.roles.ROLE_ShoutoutsAdmin
                    ]
                },
                edit: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_RoleManager,
                        this.roles.ROLE_ClientSupportAgent,
                        this.roles.ROLE_ShoutoutsAdmin
                    ]
                },
                delete: {
                    roles: [
                        this.roles.ROLE_RootAdmin,
                        this.roles.ROLE_SponsorSuperAdministrator,
                        this.roles.ROLE_SponsorAdministrator,
                        this.roles.ROLE_RoleManager,
                        this.roles.ROLE_ClientSupportAgent,
                        this.roles.ROLE_ShoutoutsAdmin
                    ]
                }
            }
        };
    }

    featureAreas: any;

    actionTypes = this.createMapFromList([
        'create',
        'edit',
        'delete',
        'view'
    ]);

    constructor(
        private enhancedAnalyticsService: EnhancedAnalyticsService,
        private authenticatedAgentService: AuthenticatedAgentService,
        private httpClient: HttpClient
    ) {
        this.setPages();
        this.setActions();
        this.featureAreas = this.createMapFromList(Object.keys(this.actions));
    }

    hasRolePermission(userRoles: any, requiredRoles: any) {
        userRoles = userRoles.map((role: { type: any; }) => role.type);
        return intersection(userRoles, requiredRoles).length > 0;
    }

    hasIdenticalRolePermission(userRoles: any, requiredRoles: any) {
        userRoles = userRoles.map((role: { type: any; }) => role.type);
        return isEqual(sortBy(userRoles), sortBy(requiredRoles));
    }

    hasFeaturePermission(userFeatures: any, requiredFeatures: any) {
        userFeatures = userFeatures.map((feature: { name: any; }) => feature.name);
        return intersection(userFeatures, requiredFeatures).length > 0;
    }

    canViewPage(state: any, user: any, roleAccessLevels?: any) {
        let hasPermission = false;

        if (state === 'legacy-analytics') {
            if (this.hasDomo) {
                return true;
            }
            if (!this.hasDomo && !this.domoChecked) {
                this.checkDomoAccess(user);
            }
            return false;
        }

        if (state.includes(['login', 'unauthorized', 'home'])) {
            return true;
        }

        if (!this.authenticatedAgentService.isUserAgent()) {
            // Perform member specific checks
            if (this.isRootAdmin(user) && state !== 'legacy-analytics' && state !== 'tpa-resources/id-card-requests') {
                return true;
            }

            if (user.sponsor === undefined || (user.sponsor.productEdition === this.CORE_SPONSOR_MEMBER && state === this.CONTENT)) {
                return false;
            }

            if (state === this.SPONSOR_SELECT && this.isDistributorAdmin(user)) {
                return true;
            }
        }

        if (has(this.pages, state)) {
            hasPermission = this.hasRolePermission(user.roles || [], this.pages[state].roles);

            if (!this.hasRolePermission(user.roles || [], this.pages[state].rolesBypass) && hasPermission && this.pages[state].features) {
                hasPermission = this.hasFeaturePermission(user.features || user.sponsor.features || [], (this.pages)[state].features);
            }

            if (state === this.CONTENT && this.checkOrgLevelRolesForContentState(user)) {
                hasPermission = roleAccessLevels.includes(this.CONTENT_ACCESS_ROLE_ALL_SPONSORS) || roleAccessLevels.includes(this.CONTENT_ACCESS_ROLE_SPONSOR);
            }
        }
        return hasPermission;
    }

    isRootAdmin(member: any) {
        if (!member) {
            return false;
        }

        return member.roles.find((role: any) => role.type === ClientRoles.RootAdmin);
    }

    isDistributorAdmin(member: any) {
        return get(member, 'distributor', 0) > 0;
    }

    canAccessFeature(user: any, featureArea: any, action: any, category: any) {
        let hasPermission = false;
        let selectedAction;

        if (!has(this.actions, featureArea) ||
            !has(this.actions[featureArea], action)) {
            return hasPermission;
        }

        if (category && has(this.actions[featureArea][action], category)) {
            selectedAction = this.actions[featureArea][action][category];
        } else {
            selectedAction = this.actions[featureArea][action];
        }

        hasPermission = this.hasRolePermission(user.roles || [], selectedAction.roles);
        if (hasPermission && has(this.features, featureArea)) {
            hasPermission = this.hasFeaturePermission(user.features || [], selectedAction.features);
        }

        return hasPermission;
    }

    createMapFromList(list: any) {
        list = list.map((val: any) => {
            return [val, val];
        });

        return Object.assign.apply(null, list.map(([key, val]: any) => {
            return { [key]: val };
        }));
    }

    getHraProvisioningPermissions(memberId: number, hraProviderType: any) {
        return this.httpClient.get(`/api/members/${memberId}/HRA/provisioning/${hraProviderType}`);
    }

    checkOrgLevelRolesForContentState(user: any) {
        return !this.hasRolePermission(user.roles, this.contentRolesExcludingSponsorAdmin)
            && (this.hasRolePermission(user.roles, [this.roles.ROLE_SponsorAdministrator])
                || (this.hasRolePermission(user.roles, [this.roles.ROLE_SponsorAdministrator])
                    && this.hasRolePermission(user.roles, [this.roles.ROLE_ReportingViewer])));
    }

    hasChallengeCsvExportPermission(member: any) {
        if (!member) {
            return false;
        }

        return !find(member.roles, { type: ClientRoles.ChallengeAdminWithoutPII })
            || some(member.roles, (role) => {
                return role.type.includes([ClientRoles.RootAdmin, ClientRoles.SponsorSuperAdmin, ClientRoles.SponsorAdmin,
                    ClientRoles.ReportingAdmin, ClientRoles.ClientSupportAgent, ClientRoles.SponsorViewer,
                    ClientRoles.ChallengeAdmin, ClientRoles.ChallengeViewer, ClientRoles.TechnicalSupport]);
            });
    }

    checkDomoAccess(user: any) {
        this.domoChecked = true;
        this.enhancedAnalyticsService.getDomoAccess(user.id)
            .subscribe((response: any) => {
                if (response.id !== null && response.id !== 0) {
                    this.hasDomo = true;
                }
            });
    }
}
