export enum MicrofrontendNames {
    Benefits = 'benefits',
    CarePathways = 'care-pathways-ui',
    Rewards = 'rewards-ui',
    ShellGenesisUiMenu = 'genesis-ui-menu',
    ShellGenesisUiNotifications = 'genesis-ui-notifications',
    ShellGenesisUi = 'shell-genesis-ui',
    Homepage = 'homepage-ui',
    Notifications = 'notifications-ui',
    Social = 'social-ui',
    Health = 'health-ui',
    Profile = 'member-profile',
    Challenges = 'challenges-ui',
    NavigationMenu = 'navigation-menu',
    LiveServices = 'live-services-ui',
    Surveys = 'surveys-ui',
    Guides = 'guides-ui',
    Footer = 'footer',
    Media = 'media-ui',
    Journeys = 'journeys-ui',
    CoachesCorner = 'coaches-corner-ui',
    Blocker = 'blocker-ui',
    GenesisMicroFrontend = 'genesis-ui',
    Transform = 'transform-ui',
    ClientAdminMicroFrontend = 'admin-ui',
    MessageCenter='message-center',
    TpaResouces='tpa-resources',
    HealthNotes='health-notes-ui',
    MessageCentre='message-centre-ui'
}
