import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KeycloakService } from './keycloak.service';
import { MemberResponseRef } from './member.service';
import { MemberClass, Feature, SingleSpaProps, Timezone, MemberSettings, Country, LanguageModel, MemberProduct, MemberTheme } from '../models';

export interface IAuthResult {
    authenticatedMember: MemberClass,
    authClient: any,
    isAuthenticatedUserAnAgent: any,
    timezone: any,
    timezones: Timezone[],
    excludeMixpanelTracking: any,
    memberFeatures: Feature[],
    memberTimezone: Timezone,
    mixpanel: any,
    memberSettings: MemberSettings,
    memberProducts: MemberProduct[],
    country: Country|null,
    languages: LanguageModel[],
    theme: MemberTheme|null
}

@Injectable()
export abstract class MemberAuthService {

    protected singleSpaProps!: Observable<SingleSpaProps>;

    setSingleSpa(value: Observable<SingleSpaProps>) {
        this.singleSpaProps = value;
    }

    abstract authenticate(): Observable<IAuthResult>;
}

@Injectable({ providedIn: 'root' })
export class MicroFrontendAuthService extends MemberAuthService {

    authenticate(): Observable<IAuthResult> {
        return this.singleSpaProps.pipe(map((response: SingleSpaProps) => {

            //TODO: Country and languages are not available in single spa props,
            //      this change requires genesis-ui to support this values as well

            const result: IAuthResult = {
                authenticatedMember: response.authenticatedMember,
                authClient: response.authClient,
                isAuthenticatedUserAnAgent: response.isAuthenticatedUserAnAgent,
                timezone: response.authenticatedMember?.timezone,
                excludeMixpanelTracking: response.authenticatedMember?.sponsor.settings.excludeMixpanelTracking,
                memberFeatures: response.memberFeatures,
                memberTimezone: response.authenticatedMember?.timezone,
                mixpanel: response.mixpanel,
                memberSettings: response.memberSettings,
                timezones: [],
                languages: [],
                memberProducts: [],
                country: null,
                theme: response.authenticatedMemberService?.theme
            };

            return result;
        }));
    }
}


@Injectable({ providedIn: 'root' })
export class ShellAuthService extends MemberAuthService {
    constructor(
        private memberResponse: MemberResponseRef,
        private authService: KeycloakService,
        @Inject('mixpanel') private mixpanel: any
    ) {
        super();
    }

    authenticate(): Observable<IAuthResult> {
        return this.memberResponse.once().pipe(map((response) => {
            const result: IAuthResult = {
                authenticatedMember: response.memberClass,
                authClient: this.authService.getClient(),
                isAuthenticatedUserAnAgent: null,
                timezone: response.memberClass.timezone,
                excludeMixpanelTracking: response.memberClass.sponsor.settings.excludeMixpanelTracking,
                memberFeatures: response.memberFeatures,
                memberTimezone: response.memberClass.timezone,
                mixpanel: this.mixpanel,
                memberSettings: response.memberSettings,
                country: response.memberCountry,
                languages: response.languages,
                timezones: response.timezones,
                memberProducts: response.memberProducts,
                theme: response.theme
            };
            return result;
        }));
    }
}
