export enum RewardCategory {
  Activity = 'Activity',
  Cards = 'Cards',
  Challenges = 'Challenges',
  Coaching = 'Coaching',
  Custom = 'Custom',
  FinancialWellness = 'FinancialWellness',
  General = 'General',
  Journeys = 'Journeys',
  Measurement = 'Measurement',
  MentalWellbeing = 'MentalWellbeing',
  Nutrition = 'Nutrition',
  OneTime = 'OneTime',
  Participation = 'Participation',
  Sleep = 'Sleep',
  Survey = 'Survey',
  Tracking = 'Tracking',
  MyCareChecklist = 'MyCareChecklist',
  Recognition = 'Recognition'
}
