import { Injectable } from '@angular/core';

import { BenefitPagesSponsorSettings } from '../graphql';

@Injectable()
export class BenefitsSponsorSettingsService {

    shouldOverwriteBenefitsText = false;
    titleToUse = '';
    titleToUseBenefits: string | undefined = '';
    titleToUsePrograms: string | undefined = '';
    sponsorSettingsLoaded = false;
    isNavigateExperienceHeader = false;
    isNavigateExperience = false;
    isTpaEnabled = false;
    navigationLayout: string | undefined = '';

    constants = {
        BENEFITS: 'Benefits',
        PROGRAMS: 'Programs'
    };

    setSettings(settings?: BenefitPagesSponsorSettings) {
        this.titleToUseBenefits = settings?.benefitsPagesSetting?.benefitsPage?.pageTitle;
        this.titleToUsePrograms = settings?.benefitsPagesSetting?.programsPage?.pageTitle;
        this.shouldOverwriteBenefitsText = Boolean(settings?.benefitsSponsorSetting.shouldOverwriteBenefitsText);
        this.isNavigateExperience = Boolean(settings?.isNavigateExperience);
        this.isTpaEnabled = Boolean(settings?.isTpaEnabled);
        this.sponsorSettingsLoaded = true;
        this.navigationLayout = settings?.sponsorSettingsUi?.navigationLayout;
    }
}
