import { Injectable } from '@angular/core';
import { ajax } from 'rxjs/ajax';

@Injectable({
    providedIn: 'root'
})
export class AppianService {
    token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJiY2IxNmE0YS1jODkxLTY1YzQtYzliNy0xNmQ3Mzc2NTU0ODgifQ.LyMHY26Hg8Tqw84K9jsb7JeOqUBS-IIKZ29czQ5PriM';
    url = 'https://virginpulse-dev.appiancloud.com/suite/webapi/GetActiveRequestsURL?user=';

    getUrl(member: string) {
        return ajax({
            url: `${this.url}${member}`,
            method: 'GET',
            responseType: 'text',
            headers: {
                Authorization: 'Bearer ' + this.token
            }
        }).toPromise().then((response) => {
            return response;
        });
    }
}
