import { domainEnvironment } from './domain/domain-environment';
import { IEnvironment, mergeEnvironment } from '../environment';

const env: IEnvironment = {
    production: false,
    pusher: {
        key: '64f5905058516b2ae3c1',
        keyEU: '52617bcc8845d5870535',
        cluster: 'mt1',
        clusterEU: 'eu',
        wsHost: 'undefined',
        wsPort: 'undefined',
        wssPort: 'undefined',
        config: {
            wsHost: 'undefined'
        }
    },
    euEnrollment: {
        baseUrl: 'https://enroll.dev.personifyhealth.eu'
    },
    naEnrollment: {
        baseUrl: 'https://enroll.dev.personifyhealth.com'
    },
    ecommerce: {
        baseUrl: 'https://shop.member.dev.personifyhealth.com'
    },
    genesis: {
        baseUrl: 'app.dev.personifyhealth.com',
        baseApiUrl: 'https://api.dev.personifyhealth.com'
    },
    env: {
        env: 'dev'
    },
    newrelic: {
        licenseKey: '1592d695bb',
        applicationID: '1134349248',
        accountID: '1105211',
        agentID: '1134349248'
    },
    walkme: {
        sourceurl: ''
    },
    s3: {
        basePath: 'dv'
    },
    autoLogoutMinutes: 15,
    logoutWarningSeconds: 60,
    authenticationProvider: 'keycloak',
    authServer: {
        agentRealm: 'agents',
        baseUrl: 'https://login.dev.personifyhealth.com',
        realmUrl: 'https://login.dev.personifyhealth.com/auth/realms/platform',
        realm: 'platform',
        clientId: 'platform-ui'
    },
    mixpanel: {
        token: 'c4615868771ec94d2854bebd7bef0eeb'
    },
    filepicker: {
        apiKey: 'ADWCWgGyuQGqoL2vo8lftz',
        virusDetectWorkflowId: '34de2cba-e987-4f10-9dd1-2f04b0d38061',
        container: 'qa-ph-platform-content'
    },
    securedFilepicker: {
        apiKey: 'AlyUg44HJQmkTL4VkfCBQz',
        virusDetectWorkflowId: '2064be5a-2f88-44ec-bbfd-9fb8f3466e8a',
        container: 'qa-secured-filestack-application'
    },
    zendesk: {
        url: 'personifyhealth1683549045.zendesk.com',
        widgetSrc: 'https://static.zdassets.com/ekr/snippet.js?key=4ea18ae8-f50e-4919-a514-e4c6270cae02'
    },
    cognigy: {
        url: 'https://endpoint-app-us.cognigy.ai/39692447d6dcf4a1083b63514e2f7399cc8d76921d5099195a863619d07b3c04',
        phaPersonalSupportUrl: 'https://endpoint-app-us.cognigy.ai/2a0d4973d8f422d96ce8b6f52a5e2d8b15e5af3e7066667acbc4409066a4b850',
        phaTechnicalSupportUrl: 'https://endpoint-app-us.cognigy.ai/39692447d6dcf4a1083b63514e2f7399cc8d76921d5099195a863619d07b3c04'
    },
    castle: {
        publishableKey: 'pk_puoHss3sRaxxLdWCz7UPJGRRFe3GwVoZ'
    },
    amCharts: {
        license: 'CH270993136'
    },
    wellsource: {
        adminCenterUrl: 'https://personifyhealth.staging.iv.wellsuite.com/WsWebAppWellSuite/admin.html'
    },
    tinyMCE: {
        apiKey: 'axdzp8me2c65ge19t3z8z4dv7owmr7q8osqct4ohea3dk5c7'
    },
    microfrontendCdn: {
        url: 'https://microfrontend-ui.qa-cdn.personifyhealth.com'
    },
    mixpanelClient: {
        token: '49fefb4f4eced6a269bd79f95fb4986a'
    },
    electron: {
        healthCenter: {
            repo: 'https://electrify.qa.personifyhealth.com/health-station/',
            channel: 'snapshot'
        }
    }
};

export const environment = mergeEnvironment(env, domainEnvironment);
