 <vpg-dialog-header class="too-many-habits-header" closeButtonAriaLabel="Close modal">
     <h2>
         {{ MODAL_HEADER }}
     </h2>
 </vpg-dialog-header>
<vpg-dialog-body>
    <p class="body-small" [translate]="'Max20Trackers'">
        You're able to track 20 Healthy Habits at a time. To add this habit, you will need to remove one first.
    </p>
    <vpg-list-next class="too-many-habits-body">
        <vpg-list-item-next *ngFor="let currentTracker of currentTrackers; index as i">
            <img vpgImage
                 vpgListItemImage
                 type="avatar"
                 size="small"
                 [id]="'tracker-image-' + i"
                 src="{{ currentTracker.tracker.backgroundImage }}"
                 alt="An avatar"
            >
            <vpg-list-item-heading level="3"
                                   [id]="'tracker-title-' + i"
                                   class="hh-list-item-heading">
                {{ currentTracker.tracker.title }}
                <div>
                    <vpg-toggle-switch class="mobile-switch"
                                       [id]="'tracker-switch-' + i"
                                       [name]="currentTracker.tracker.title"
                                       [disabled]="currentTracker.hasActiveChallenge || false"
                                       (valueChange)="onToggle($event, currentTracker)"
                                       [(ngModel)]="currentTracker.isActive"></vpg-toggle-switch>
                </div>
            </vpg-list-item-heading>
            <vpg-list-item-content>
                <div [id]="'tracker-description-' + i">
                    {{ currentTracker.tracker.description }}
                </div>
                <vpg-inline-label *ngIf="currentTracker.hasActiveChallenge"
                                  variant="neutral"
                                  category="urgent"
                                  size="small"
                                  [text]="'CantRemoveTrackerActiveChallenge' | translate"
                                  class="vp-margin-top-5"
                >
                </vpg-inline-label>
            </vpg-list-item-content>
            <vpg-list-item-action class="desktop-switch">
                <vpg-toggle-switch [name]="currentTracker.tracker.title"
                                   [id]="'healthy-habit-toggle-switch-' + i"
                                   [disabled]="currentTracker.hasActiveChallenge || false"
                                   (valueChange)="onToggle($event, currentTracker)"
                                   [(ngModel)]="currentTracker.isActive"></vpg-toggle-switch>
            </vpg-list-item-action>
        </vpg-list-item-next>
    </vpg-list-next>
</vpg-dialog-body>
<vpg-dialog-actions>
    <button vpg-button
            id="too-many-habits-done-button"
            variant="primary"
            size="medium"
            [disabled]="!hasChanged"
            (click)="confirm()"
    >
        Done
    </button>
</vpg-dialog-actions>
