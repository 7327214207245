export const RouteOptions = {
    homeRouteOptions: {
        module: 'homepage-ui',
        matcher: '^#/home'
    },
    socialRouteOptions: {
        module: 'social-ui',
        matcher: '^#/social|#/allfriends|#/addfriends|#/invitefriends|#/friends|#/steps-leaderboard|#/recognition|#/groups'
    },
    memberRouteOptions: {
        module: 'member-profile',
        matcher: '^#/member-profile'
    },
    benefitsRouteOptions: {
        module: 'benefits',
        matcher: '^#/benefits'
    },
    rewardsRouteOptions: {
        module: 'rewards-ui',
        matcher: '^#/my-rewards'
    },
    coachesCornerRouteOptions: {
        module: 'coaches-corner-ui',
        matcher: '^#/coaches-corner'
    },
    guidesRouteOptions: {
        module: 'guides-ui',
        matcher: '^#/guide|#/mobile/guide'
    },
    healthRouteOptions: {
        module: 'health-ui',
        matcher: '^#/health-ui|/#health-ui/my-care-checklist|/#health-ui/situations'
    },
    mediaRouteOptions: {
        module: 'media-ui',
        matcher: '^#/media'
    },
    liveServiceRouteOptions: {
        module: 'live-services-ui',
        matcher: '^#/live-services-coaching|#/live-services-guides'
    },
    journeysRouteOptions: {
        module: 'journeys-ui',
        matcher: '^#/journeys'
    },
    healthyHabitsRouteOptions: {
        module: 'healthy-habits',
        matcher: '^#/healthyhabits'
    },
    calendarRouteOptions: {
        module: 'calendar-ui',
        matcher: '^#/calendar'
    },
    newsFlashRouteOptions: {
        module: 'newsflash-ui',
        matcher: '^#/newsFlash'
    },
    myFriendsRouteOptions: {
        module: 'my-friends-app',
        matcher: '^#/myfriends',
        importModule: 'genesis-ui'
    },
    vendorRouteOptions: {
        module: 'vendor-app',
        matcher: '^#/vendor',
        importModule: 'genesis-ui'
    },
    sevenDayLeaderboardRouteOptions: {
        module: '7day-leaderboard-app',
        matcher: '^#/7day-leaderboard',
        importModule: 'genesis-ui'
    },
    surveysRouteOptions: {
        module: 'surveys-ui',
        matcher: '^#/surveys-ui'
    },
    pillarsRouteOptions: {
        module: 'pillars-and-topics-ui',
        matcher: '^#/pillars'
    },
    transformRouteOptions: {
        module: 'transform-ui',
        matcher: '^#/transform'
    },
    achdepositRouteOptions: {
        module: 'achdeposit-genesis-ui',
        matcher: '^#/achdeposit',
        importModule: 'genesis-ui'
    },
    redeemCreditsRouteOptions: {
        module: 'redeem-credits-genesis-ui',
        matcher: '^#/redeemcredits',
        importModule: 'genesis-ui'
    },
    missingStepsRouteOptions: {
        module: 'missingSteps-genesis-ui',
        matcher: '^#/missingSteps',
        importModule: 'genesis-ui'
    },
    previewBoardsRouteOptions: {
        module: 'preview-boards',
        matcher: '^#/preview/boards',
        importModule: 'genesis-ui'
    },
    newMiniAppsRouteOptions: {
        module: 'new-mini-apps',
        matcher: '^#/welcome.html|^#/logout.html|^#/enrollmentSearch.html'
    },
    healthRiskAssesment: {
        module: 'health-risk-assesment-app',
        matcher: '^#/hra/start',
        importModule: 'genesis-ui'
    },
    webchatRouteOptions: {
        module: 'webchat-ui',
        matcher: '^#/webchat'
    },
    challengesInterrupt: {
        module: 'challenges-ui',
        matcher: '^#/challenges'
    },
    partnerInvite: {
        module: 'partner-genesis-ui',
        matcher: '^#/partnerinvite',
        importModule: 'genesis-ui'
    },
    holisticChallenges: {
        module: 'challenges-ui',
        matcher: '^#/holistic-challenges'
    },
    goalChallenges: {
        module: 'challenges-ui',
        matcher: '^#/goal-challenges'
    },
    tpaResourcesRouteOptions: {
        module: 'tpa-resources',
        matcher: '^#/tpa-resources'
    },
    messageCentreRouteOptions: {
        module: 'message-centre-ui',
        matcher: '^#/message-centre-ui'
    },
    statsPage: {
        module: 'stats-page',
        matcher: '^#/stats-page'
    },
    oldStatsPage: {
        module: 'stats-page-genesis-ui',
        matcher: '^#/mystats',
        importModule: 'genesis-ui'
    },
    messageCenterRouteOptions: {
        module: 'message-center',
        matcher: '^#/message-center'
    }
};
