import { Component, Output, EventEmitter } from '@angular/core';

import { PalService } from './pal.service';
import { FeatureEnum, FeatureService, PalMemberPromotedAction } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-pal-modal',
    templateUrl: './pal-modal.component.html',
    styleUrls: ['./pal-modal.component.scss']
})
export class PalModalComponent {
    readonly promotedActions$ = this.palService.memberPromotedActions$;
    private readonly howToEarnUrl = '#/my-rewards/earn';
    readonly rewardFeatures = [FeatureEnum.RewardsAndProgram, FeatureEnum.GenesisRewards];
    readonly TRANSLATIONS = {
        MY_ACTION: 'MyActions',
        CLOSE: 'Close',
        VIEW_REWARDS:'ViewRewards'
    };

    @Output() closeDialog = new EventEmitter<string | boolean>();
    hasRewardsAndProgram = this.featureService.hasEveryFeature(this.rewardFeatures);
    @Output() cardClicked: EventEmitter<PalMemberPromotedAction[]> = new EventEmitter<PalMemberPromotedAction[]>();

    constructor(
        private featureService: FeatureService,
        private palService: PalService
    ) {}

    close(): void {
        this.closeDialog.emit('Closed');
    }

    viewRewards(): void {
        window.location.href = this.howToEarnUrl;
    }

    onCardClick(card: PalMemberPromotedAction, analyticsIndex: number): void {
        this.palService.handleCardClick(card, analyticsIndex);
    }
}
