import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  /**  A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.  */
  DateTime: { input: any; output: any; }
  /**  The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf).  */
  JSON: { input: any; output: any; }
  Long: { input: any; output: any; }
  ObjMap: { input: any; output: any; }
  /**  A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt.  */
  URL: { input: any; output: any; }
  UUID: { input: any; output: any; }
  /** Represents empty values */
  Void: { input: any; output: any; }
};

/** Measurement data of activity input */
export type ActivityMeasurement = {
  /** Source App if known */
  app?: InputMaybe<Scalars['String']['input']>;
  /** Source Device if known */
  device?: InputMaybe<Scalars['String']['input']>;
  measurementType: ActivityMeasurementType;
  /** Timestamp of measurement. E.g.: 1996-12-19T16:39:57-08:00 or 1996-12-19T16:39:57Z */
  timestamp: Scalars['DateTime']['input'];
  unit: MeasurementUnit;
  /** Is the measurement from a validated source? */
  validated: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

/** Type of activity measurement */
export enum ActivityMeasurementType {
  /** Cumulative Active Minutes measurement. Units: MILLISECOND */
  CumulativeActiveMinutes = 'CUMULATIVE_ACTIVE_MINUTES',
  /** Cumulative Calories(kcal) measured. Units: KILOCALORIE */
  CumulativeCaloriesBurned = 'CUMULATIVE_CALORIES_BURNED',
  /** Cumulative Distance measurement. Units: KILOMETER | METER */
  CumulativeDistance = 'CUMULATIVE_DISTANCE',
  /** Cumulative steps measured. Units: COUNT */
  CumulativeSteps = 'CUMULATIVE_STEPS'
}

/** Supported providers */
export enum ActivityProvider {
  AppleHealth = 'APPLE_HEALTH',
  GoogleFit = 'GOOGLE_FIT',
  Shealth = 'SHEALTH'
}

/** Supported vendors */
export enum ActivityVendor {
  AppleHealth = 'APPLE_HEALTH',
  GoogleFit = 'GOOGLE_FIT',
  Shealth = 'SHEALTH'
}

/** Appointment Request Messaging is a request for a session between a coach and a participant in which a user chats with coach via messaging. */
export type AppointmentRequestMessaging = {
  __typename?: 'AppointmentRequestMessaging';
  /** The date the that the appointment request for messaging was made */
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  /** Id of the appointment request message */
  id?: Maybe<Scalars['Long']['output']>;
  /** MemberId which the member request message belongs to */
  memberId: Scalars['Long']['output'];
  /** Represents the status of the appointment request message. This will change based on */
  status: AppointmentRequestMessagingStatus;
  /** Program Topic associated with the appointment request messaging */
  topicDisplayName: Scalars['String']['output'];
};

/** Enums representing the status of appointment request messaging. Accepted means that user the messaging appointment has been created, cancelled means that it has been cancelled and no longer being created, Pending means that the appointment request messaging is still waiting on a coach to pick it up, and transferred means a user chose to create a telephonic appointment instead */
export enum AppointmentRequestMessagingStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Open = 'OPEN',
  Transferred = 'TRANSFERRED'
}

export type BenefitPagesSponsorSettings = {
  __typename?: 'BenefitPagesSponsorSettings';
  benefitsPagesSetting?: Maybe<BenefitsPagesSetting>;
  benefitsSponsorSetting: BenefitSponsorSetting;
  isNavigateExperience: Scalars['Boolean']['output'];
  isTpaEnabled: Scalars['Boolean']['output'];
  sponsorSettings: SponsorSettingsBenefits;
  sponsorSettingsUi: SponsorSettingsUiBenefits;
  tpaSponsorSetting: TpaSponsorSettings;
};

export type BenefitSponsorSetting = {
  __typename?: 'BenefitSponsorSetting';
  customLogoUrl?: Maybe<Scalars['String']['output']>;
  shouldDisplayRecommended: Scalars['Boolean']['output'];
  shouldOverwriteBenefitsText: Scalars['Boolean']['output'];
  vpNavigateOption?: Maybe<Scalars['String']['output']>;
};

export type BenefitsPageLayout = {
  __typename?: 'BenefitsPageLayout';
  pageTitle: Scalars['String']['output'];
};

export type BenefitsPagesSetting = {
  __typename?: 'BenefitsPagesSetting';
  benefitsPage?: Maybe<BenefitsPageLayout>;
  programsPage?: Maybe<BenefitsPageLayout>;
};

export type Claim = {
  __typename?: 'Claim';
  claimNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  patientFirstName: Scalars['String']['output'];
  patientLastName: Scalars['String']['output'];
  patientResponsibilityAmount?: Maybe<Scalars['Float']['output']>;
  planAllowedAmount?: Maybe<Scalars['Float']['output']>;
  planPaidAmount?: Maybe<Scalars['Float']['output']>;
  provider?: Maybe<ClaimProvider>;
  serviceEndDate?: Maybe<Scalars['Date']['output']>;
  serviceItems?: Maybe<ServiceItems>;
  serviceStartDate?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalChargedAmount?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type ClaimConnection = {
  __typename?: 'ClaimConnection';
  /** List of claims edges. */
  edges?: Maybe<Array<Maybe<ClaimEdge>>>;
  /** Information about the page of claims. */
  pageInfo: PageInfo;
};

/** Represents a rewards edge in a connection */
export type ClaimEdge = {
  __typename?: 'ClaimEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The node containing the actual claim data. */
  node?: Maybe<Claim>;
};

export type ClaimProvider = {
  __typename?: 'ClaimProvider';
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Unique identifier for activity or sleep mutations */
export type ClientMutationId = {
  __typename?: 'ClientMutationId';
  /** Identifier value */
  value: Scalars['ID']['output'];
};

/** CoachDetails represents summary of a coach details */
export type CoachDetails = {
  __typename?: 'CoachDetails';
  /** The biogrophy associated with the coach */
  coachBio?: Maybe<Scalars['String']['output']>;
  /** The first name of the coach */
  coachFirstName: Scalars['String']['output'];
  /** The last name of the coach */
  coachName: Scalars['String']['output'];
  /** Shows if the coach is currently active */
  isActive: Scalars['Boolean']['output'];
  /** Shows whether the coach is out of office */
  isOutOfOffice: Scalars['Boolean']['output'];
  /** Shows the date the coach will return if is not active or is out of office */
  returnDate?: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a Coaching profile for a user */
export type CoachingProfile = {
  __typename?: 'CoachingProfile';
  /** The coach id which is associated with an active session */
  activeCoachId?: Maybe<Scalars['Long']['output']>;
  /** Id associated with a topic a user is active in */
  activeTopicId?: Maybe<Scalars['Long']['output']>;
  /** The package names which a user is qualified for */
  allLiveServicesPackageNames: Array<Maybe<Scalars['String']['output']>>;
  /** The coach details of the coach which is associated with the active session of the user */
  coachDetails?: Maybe<CoachDetails>;
  /** Engagement status for a user */
  engagementStatus: Scalars['String']['output'];
  /** Represents if a user has an active coaching session */
  isActiveInCoaching: Scalars['Boolean']['output'];
  /** Whether the engagement of the user is endging */
  isEngagementEnding?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the preengagement of the user is endging */
  isPreEngagementEnding?: Maybe<Scalars['Boolean']['output']>;
  /** The latest messaging appointment request associated with the user */
  latestMessagingAppointmentRequest?: Maybe<AppointmentRequestMessaging>;
};

export type CoachingProfileResult = CoachingProfile | ErrorResponse;

/** Type that describes the country object */
export type Country = Node & {
  __typename?: 'Country';
  /** Country code */
  code?: Maybe<Scalars['String']['output']>;
  /** Country cookie policy requirement */
  cookiePolicy: Scalars['Boolean']['output'];
  /** Country ID */
  id: Scalars['ID']['output'];
  /** Country name */
  name: Scalars['String']['output'];
  /** Country phone code */
  phoneCode?: Maybe<Scalars['String']['output']>;
  /** Country store url */
  storeUrl?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items */
export type CountryConnection = {
  __typename?: 'CountryConnection';
  /** A list of edges */
  edges: Array<CountryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection */
export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** A Cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Country;
};

/** Input for creating health measurements */
export type CreateHealthMeasurementsInput = {
  measurements: Array<InputMaybe<HealthMeasurementInput>>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  personId: Scalars['String']['input'];
  region: Region;
  sourceType: Scalars['String']['input'];
};

export type CustomResources = {
  __typename?: 'CustomResources';
  link: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

/** Response object for the enrollment information */
export type EnrollmentStatus = {
  __typename?: 'EnrollmentStatus';
  /** True or false value that represent whether or not the member is enrolled into the platform */
  enrolled?: Maybe<Scalars['Boolean']['output']>;
  /** Current status of the searched member */
  status?: Maybe<ProfileStatus>;
};

export type EnrollmentStatusResult = EnrollmentStatus | ErrorResponse;

export type ErrorMessage = {
  /** Error message. */
  message?: Maybe<Scalars['String']['output']>;
};

/** Type that describes error response. */
export type ErrorResponse = {
  __typename?: 'ErrorResponse';
  /** Error message of the response */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Http error status of the response. */
  errorStatus?: Maybe<HttpStatus>;
};

export type EvaluatedFeatureToggle = {
  __typename?: 'EvaluatedFeatureToggle';
  enabled: Scalars['Boolean']['output'];
  featureTogglePayload: FeatureTogglePayload;
  name: Scalars['String']['output'];
};

export type FeatureTogglePayload = {
  __typename?: 'FeatureTogglePayload';
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  payload?: Maybe<Scalars['String']['output']>;
  payloadType: Scalars['String']['output'];
};

/** Field error */
export type FieldError = {
  __typename?: 'FieldError';
  /** An error code */
  errorCode?: Maybe<Scalars['String']['output']>;
  /** An error message for field */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** A field name */
  field?: Maybe<Scalars['String']['output']>;
};

export type FindCareFeatures = {
  __typename?: 'FindCareFeatures';
  hasFindCare?: Maybe<Scalars['Boolean']['output']>;
  hasFindCareDoctor?: Maybe<Scalars['Boolean']['output']>;
  hasFindCareOnlineDoctor?: Maybe<Scalars['Boolean']['output']>;
  hasFindCareProcedure?: Maybe<Scalars['Boolean']['output']>;
};

/** Types of Gender Identities */
export enum GenderIdentity {
  DoNotIdentifyAsMaleOrFemale = 'DO_NOT_IDENTIFY_AS_MALE_OR_FEMALE',
  Female = 'FEMALE',
  Male = 'MALE',
  PreferNotToAnswer = 'PREFER_NOT_TO_ANSWER'
}

/** Type that describes the gender identity object */
export type GenderIdentityDescriptor = Node & {
  __typename?: 'GenderIdentityDescriptor';
  /** Specifies if gender identity is binary */
  binary?: Maybe<Scalars['Boolean']['output']>;
  /** Gender identity description */
  description?: Maybe<Scalars['String']['output']>;
  /** Gender identity code */
  genderCode?: Maybe<Scalars['String']['output']>;
  /** Gender identity name */
  id: Scalars['ID']['output'];
};

/** A connection to a list of items */
export type GenderIdentityDescriptorConnection = {
  __typename?: 'GenderIdentityDescriptorConnection';
  /** A list of edges */
  edges: Array<GenderIdentityDescriptorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection */
export type GenderIdentityDescriptorEdge = {
  __typename?: 'GenderIdentityDescriptorEdge';
  /** A Cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: GenderIdentityDescriptor;
};

export enum HttpMethod {
  Connect = 'CONNECT',
  Delete = 'DELETE',
  Get = 'GET',
  Head = 'HEAD',
  Options = 'OPTIONS',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
  Trace = 'TRACE'
}

/** A type represending a single HealthMeasurement object */
export type HealthMeasurement = {
  __typename?: 'HealthMeasurement';
  /** Created date. */
  createdDate?: Maybe<Scalars['String']['output']>;
  /** Data collection method. Enumerated in db. */
  dataCollectionMethod?: Maybe<Scalars['String']['output']>;
  /** HealthMeasurement UUID */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Measurement date. */
  measurementDate: Scalars['String']['output'];
  /** Member id */
  memberId?: Maybe<Scalars['Long']['output']>;
  /** Person id */
  personId?: Maybe<Scalars['Long']['output']>;
  /** Source id. Will link to further information in Ingestion. */
  sourceId?: Maybe<Scalars['String']['output']>;
  /** Source service. Enumerated in db. */
  sourceService?: Maybe<Scalars['String']['output']>;
  /** Source type. Enumerated in db. */
  sourceType?: Maybe<Scalars['String']['output']>;
  /** Type of HealthMeasurement (enum) */
  type: HealthMeasurementType;
  /** Updated date. */
  updatedDate?: Maybe<Scalars['String']['output']>;
  /** Value of HealthMeasurement */
  value: Scalars['Float']['output'];
};

/** Input for individual health measurements in CreateHealthMeasurementsInput */
export type HealthMeasurementInput = {
  dataCollectionMethod: Scalars['String']['input'];
  measurementDate: Scalars['String']['input'];
  sourceId: Scalars['String']['input'];
  type: Scalars['String']['input'];
  unit: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

/** Type of Health Measurement Types */
export enum HealthMeasurementType {
  A1C = 'A1C',
  Bmi = 'BMI',
  BloodPressurePulse = 'BloodPressurePulse',
  BodyFat = 'BodyFat',
  BodyTemperature = 'BodyTemperature',
  CholesterolHdl = 'CholesterolHDL',
  CholesterolLdl = 'CholesterolLDL',
  CholesterolTotal = 'CholesterolTotal',
  CholesterolTriglyceride = 'CholesterolTriglyceride',
  DiastolicBloodPressure = 'DiastolicBloodPressure',
  GlucoseFasting = 'GlucoseFasting',
  GlucoseNonFasting = 'GlucoseNonFasting',
  Height = 'Height',
  HipCircumference = 'HipCircumference',
  SystolicBloodPressure = 'SystolicBloodPressure',
  WaistCircumference = 'WaistCircumference',
  Weight = 'Weight'
}

export type HraPreferredNameVm = {
  __typename?: 'HraPreferredNameVM';
  abbreviation?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
};

/** Type of http statuses. */
export enum HttpStatus {
  BadRequest = 'BAD_REQUEST',
  Forbidden = 'FORBIDDEN',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export type InsurancePlan = {
  __typename?: 'InsurancePlan';
  attachments?: Maybe<Array<Maybe<InsurancePlanAttachment>>>;
  coverage?: Maybe<InsurancePlanCoverage>;
  id?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  longDescription?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  subscriber?: Maybe<InsurancePlanMember>;
  type?: Maybe<InsurancePlanType>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type InsurancePlanAccumulators = {
  __typename?: 'InsurancePlanAccumulators';
  accumulatorData: Array<Maybe<InsurancePlanAccumulatorsCoverage>>;
  lastUpdate: Scalars['Date']['output'];
  planId: Scalars['Int']['output'];
  planLevel: Scalars['String']['output'];
};

export type InsurancePlanAccumulatorsCoverage = {
  __typename?: 'InsurancePlanAccumulatorsCoverage';
  applied?: Maybe<Scalars['String']['output']>;
  coverage: Scalars['String']['output'];
  level: Scalars['String']['output'];
  limit?: Maybe<Scalars['String']['output']>;
  network: Scalars['String']['output'];
  progress: Scalars['Int']['output'];
  remaining?: Maybe<Scalars['String']['output']>;
};

export type InsurancePlanAttachment = {
  __typename?: 'InsurancePlanAttachment';
  format?: Maybe<Scalars['String']['output']>;
  linkUrl?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type InsurancePlanConnection = {
  __typename?: 'InsurancePlanConnection';
  data?: Maybe<Array<Maybe<InsurancePlan>>>;
};

export type InsurancePlanCoverage = {
  __typename?: 'InsurancePlanCoverage';
  amounts: Array<Maybe<InsurancePlanCoverageAmount>>;
  showAccumulators: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type InsurancePlanCoverageAmount = {
  __typename?: 'InsurancePlanCoverageAmount';
  amount?: Maybe<Scalars['Float']['output']>;
  coverage: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  network: Scalars['String']['output'];
  planLevel: Scalars['String']['output'];
};

export type InsurancePlanFeatures = {
  __typename?: 'InsurancePlanFeatures';
  hasDentalInsurancePlan: Scalars['Boolean']['output'];
  hasIdCards: Scalars['Boolean']['output'];
  hasInsuranceClaims: Scalars['Boolean']['output'];
  hasMedicalInsurancePlan: Scalars['Boolean']['output'];
  hasVisionInsurancePlan: Scalars['Boolean']['output'];
};

export type InsurancePlanMember = {
  __typename?: 'InsurancePlanMember';
  active?: Maybe<Scalars['Boolean']['output']>;
  consent?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  effectiveEndDate?: Maybe<Scalars['Date']['output']>;
  effectiveStartDate?: Maybe<Scalars['Date']['output']>;
  enterprisePersonId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  primarySubscriber?: Maybe<Scalars['Boolean']['output']>;
  subscriberId?: Maybe<Scalars['String']['output']>;
};

export type InsurancePlanMemberConnection = {
  __typename?: 'InsurancePlanMemberConnection';
  data?: Maybe<Array<Maybe<InsurancePlanMember>>>;
};

export type InsurancePlanResources = {
  __typename?: 'InsurancePlanResources';
  insuranceCardBack?: Maybe<Scalars['String']['output']>;
  insuranceCardFront?: Maybe<Scalars['String']['output']>;
  prescriptionDrugList?: Maybe<Scalars['String']['output']>;
  resources?: Maybe<Array<Maybe<CustomResources>>>;
  summaryOfBenefits?: Maybe<Scalars['String']['output']>;
  summaryPlanDescription?: Maybe<Scalars['String']['output']>;
};

export enum InsurancePlanType {
  Dental = 'Dental',
  Medical = 'Medical',
  Vision = 'Vision'
}

/** Type that describes the language object */
export type LanguageDescriptor = Node & {
  __typename?: 'LanguageDescriptor';
  /** Language code */
  code?: Maybe<Scalars['String']['output']>;
  /** Language description */
  description?: Maybe<Scalars['String']['output']>;
  /** Language english name */
  englishNative?: Maybe<Scalars['String']['output']>;
  /** Language name */
  id: Scalars['ID']['output'];
  /** Specifies if language is selectable */
  selectable?: Maybe<Scalars['Boolean']['output']>;
};

/** A connection to a list of items */
export type LanguageDescriptorConnection = {
  __typename?: 'LanguageDescriptorConnection';
  /** A list of edges */
  edges: Array<LanguageDescriptorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection */
export type LanguageDescriptorEdge = {
  __typename?: 'LanguageDescriptorEdge';
  /** A Cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: LanguageDescriptor;
};

/** Measurement units for various measurement types */
export enum MeasurementUnit {
  Count = 'COUNT',
  Kilocalorie = 'KILOCALORIE',
  Meter = 'METER',
  Millimeter = 'MILLIMETER',
  Millisecond = 'MILLISECOND'
}

export type MemberActivityMutationResult = ClientMutationId | ProviderAndVendorNotMatching;

export type MemberBenefitsFeatures = {
  __typename?: 'MemberBenefitsFeatures';
  findCareFeatures: FindCareFeatures;
  insurancePlanFeatures: InsurancePlanFeatures;
};

export type MemberInsuranceConsent = {
  __typename?: 'MemberInsuranceConsent';
  consent?: Maybe<Scalars['String']['output']>;
  dateOfConsent?: Maybe<Scalars['Date']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
};

export type MemberSettings = {
  __typename?: 'MemberSettings';
  hasSeenDomainNavigationMobile?: Maybe<Scalars['Boolean']['output']>;
  hasSeenDomainNavigationWeb?: Maybe<Scalars['Boolean']['output']>;
  hasSeenGoalSetting?: Maybe<Scalars['Boolean']['output']>;
  hideOnboardingItems?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isNicotineFree?: Maybe<Scalars['Boolean']['output']>;
  lastSeenCompanyUpdates?: Maybe<Scalars['Void']['output']>;
  lastSeenNewFeature?: Maybe<Scalars['Void']['output']>;
  lastSeenTrackerChallengeRecommendation?: Maybe<Scalars['Void']['output']>;
  onboardingComplete?: Maybe<Scalars['Boolean']['output']>;
  showTimezoneAlert?: Maybe<Scalars['Boolean']['output']>;
  stepsGoal?: Maybe<Scalars['Int']['output']>;
  themeId?: Maybe<Scalars['Void']['output']>;
};

/** Metric data */
export type Metric = {
  origin: Scalars['String']['input'];
  type: Scalars['String']['input'];
  unit: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Source of metric data */
export type MetricSource = {
  device?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type MetricUpdateResult = {
  __typename?: 'MetricUpdateResult';
  value: Scalars['Int']['output'];
};

/** GraphQL Mutations */
export type Mutation = {
  __typename?: 'Mutation';
  /** Mutation to create new HealthMeasurements. */
  createHealthMeasurements?: Maybe<ProducerResponse>;
  /** Mutation to create or update activity data */
  updateActivity?: Maybe<MemberActivityMutationResult>;
  /** Mutation to create or update metrics */
  updateMetrics?: Maybe<MetricUpdateResult>;
  /** Mutation for updating current user profile */
  updateProfile?: Maybe<UpdateProfileResponse>;
  /** Mutation to create or update sleep data */
  updateSleep?: Maybe<MemberActivityMutationResult>;
};


/** GraphQL Mutations */
export type MutationCreateHealthMeasurementsArgs = {
  healthMeasurements: CreateHealthMeasurementsInput;
};


/** GraphQL Mutations */
export type MutationUpdateActivityArgs = {
  activityUpdate: UpdateActivityInput;
};


/** GraphQL Mutations */
export type MutationUpdateMetricsArgs = {
  metricUpdate: UpdateMetricInput;
};


/** GraphQL Mutations */
export type MutationUpdateProfileArgs = {
  input?: InputMaybe<UpdateProfileInput>;
};


/** GraphQL Mutations */
export type MutationUpdateSleepArgs = {
  sleepUpdate: UpdateSleepInput;
};

export type Node = {
  id: Scalars['ID']['output'];
};

/** Info of a single page. This is used when returned type is paginated list. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Ending cursor. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Boolean to determine if the current page has next page. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Boolean to determine if the current page has previous page. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Starting cursor. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Member phone settings */
export type PhoneSettings = {
  __typename?: 'PhoneSettings';
  /** Phone home number */
  homeNumber?: Maybe<Scalars['String']['output']>;
  /** Phone home number iso code */
  homeNumberIsoCode?: Maybe<Scalars['String']['output']>;
  /** Mobile phone number */
  mobileNumber?: Maybe<Scalars['String']['output']>;
  /** Mobile phone number iso code */
  mobileNumberIsoCode?: Maybe<Scalars['String']['output']>;
  /** Specifies if mobile phone number is verified */
  mobileNumberVerified?: Maybe<Scalars['Boolean']['output']>;
};

/** A response type returned after creating new HealthMeasurements detailing the payload that was sent and where. */
export type ProducerResponse = {
  __typename?: 'ProducerResponse';
  /** Payload that was sent. */
  payload?: Maybe<Scalars['String']['output']>;
  /** Region the payload was sent to. */
  region?: Maybe<Scalars['String']['output']>;
  /** Topic the payload was sent to. */
  topic?: Maybe<Scalars['String']['output']>;
};

/** Type that describes the profile object */
export type Profile = {
  __typename?: 'Profile';
  /** Member's bio */
  bio?: Maybe<Scalars['String']['output']>;
  /** Member's country */
  country?: Maybe<Country>;
  /** Member's date of birth */
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  /** Display name */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Email address of the member */
  emailAddress?: Maybe<Scalars['String']['output']>;
  /** Date when the member enrolled onto the platform */
  enrollmentDate?: Maybe<Scalars['DateTime']['output']>;
  /** Identifier used on platform level */
  enterprisePersonId?: Maybe<Scalars['String']['output']>;
  /** Identifier used for external transactions */
  externalId?: Maybe<Scalars['String']['output']>;
  /** First name of the member */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Gender which member identifies as */
  genderIdentity?: Maybe<GenderIdentity>;
  /** Profile id */
  id: Scalars['ID']['output'];
  /** Member's preferred language */
  language?: Maybe<LanguageDescriptor>;
  /** Last name of the member */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Member id */
  memberId: Scalars['ID']['output'];
  /** Person id */
  personId: Scalars['ID']['output'];
  /** Phone settings */
  phoneSettings?: Maybe<PhoneSettings>;
  /** Member's profile picture */
  picture?: Maybe<Scalars['String']['output']>;
  /** Sponsor id */
  sponsorId?: Maybe<Scalars['ID']['output']>;
  /** Member's timezone */
  timezone?: Maybe<TimezoneDescriptor>;
  /** Which system of measurement the member prefers */
  unitOfMeasurement?: Maybe<UnitOfMeasurement>;
  /** Username of the member */
  username?: Maybe<Scalars['String']['output']>;
};

/** List of possible values a member status can be in */
export enum ProfileStatus {
  /** Member is active in the platform */
  Active = 'ACTIVE',
  /** Member is excluded from the platform */
  Cancelled = 'CANCELLED',
  /** Member is active but going to be excluded from the platform after a certain period */
  PendingCancel = 'PENDING_CANCEL'
}

/** Return type indicating an error state where different provider and vendor values were given for mutation input */
export type ProviderAndVendorNotMatching = {
  __typename?: 'ProviderAndVendorNotMatching';
  /** Error message */
  message: Scalars['String']['output'];
  /** Provider provided with input */
  provider: ActivityProvider;
  /** Vendor provided with input */
  vendor: ActivityVendor;
};

/** GraphQL Queries */
export type Query = {
  __typename?: 'Query';
  /** Test query, always returns null */
  activity?: Maybe<Scalars['String']['output']>;
  benefitPagesSponsorSettings?: Maybe<BenefitPagesSponsorSettings>;
  /** Coaching Profile for a user. */
  coachingProfile?: Maybe<CoachingProfileResult>;
  /** Query for fetching list of countries */
  countries: CountryConnection;
  /** Query for fetching the enrollment status to see if the member is enrolled on the platform */
  enrollmentStatusByEmployeeId?: Maybe<EnrollmentStatusResult>;
  featureToggles: Array<Maybe<EvaluatedFeatureToggle>>;
  /** Query for fetching list of genders */
  genders: GenderIdentityDescriptorConnection;
  /** Query for health measurements by personId. Optionally filters by UTC start date, UTC end date, and/or type. Date formatting: "yyyy-MM-dd" */
  healthMeasurements: Array<Maybe<HealthMeasurement>>;
  insuranceClaims?: Maybe<ClaimConnection>;
  insurancePlan?: Maybe<InsurancePlan>;
  insurancePlanAccumulators?: Maybe<InsurancePlanAccumulators>;
  insurancePlanMembers?: Maybe<InsurancePlanMemberConnection>;
  insurancePlans?: Maybe<InsurancePlanConnection>;
  /** Query for fetching list of languages */
  languages: LanguageDescriptorConnection;
  memberBenefitsFeatures?: Maybe<MemberBenefitsFeatures>;
  memberInsuranceConsent?: Maybe<MemberInsuranceConsent>;
  memberSettings?: Maybe<MemberSettings>;
  memberSponsor?: Maybe<Sponsor>;
  /** Query for fetching current user profile */
  profile?: Maybe<Profile>;
  /** sayHealthNotes is a dummy field with no controller to fulfill it. It is here to avoid an empty schema issue and will return null by default unless you add a controller or remove it entirely. Recommendation: Remove it entirely after adding your first legitimate field. */
  sayHealthNotes?: Maybe<Scalars['String']['output']>;
  /** sayHelloCTTool is a dummy field with no controller to fulfill it. It is here to avoid an empty schema issue and will return null by default unless you add a controller or remove it entirely. Recommendation: Remove it entirely after adding your first legitimate field. */
  sayHelloCTTool?: Maybe<Scalars['String']['output']>;
  /** Test query, always returns null */
  sleep?: Maybe<Scalars['String']['output']>;
  sponsorSettings?: Maybe<SponsorSettings>;
  /** Test query, always returns null Subject to unannounced changes. */
  syntheticU?: Maybe<Scalars['String']['output']>;
  /** Query for fetching list of timezones */
  timezones: TimezoneDescriptorConnection;
  trophies?: Maybe<Array<Maybe<Trophy>>>;
  /** Query for fetching list of unit of measurements */
  unitOfMeasurements: UnitOfMeasurementDescriptorConnection;
};


/** GraphQL Queries */
export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** GraphQL Queries */
export type QueryEnrollmentStatusByEmployeeIdArgs = {
  employeeId: Scalars['ID']['input'];
};


/** GraphQL Queries */
export type QueryGendersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  withNonBinary?: Scalars['Boolean']['input'];
};


/** GraphQL Queries */
export type QueryHealthMeasurementsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  personId: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  type?: InputMaybe<HealthMeasurementType>;
};


/** GraphQL Queries */
export type QueryInsuranceClaimsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** GraphQL Queries */
export type QueryInsurancePlanArgs = {
  planId?: InputMaybe<Scalars['Int']['input']>;
  planType?: InputMaybe<Scalars['String']['input']>;
};


/** GraphQL Queries */
export type QueryInsurancePlanAccumulatorsArgs = {
  planType?: InputMaybe<Scalars['String']['input']>;
};


/** GraphQL Queries */
export type QueryInsurancePlanMembersArgs = {
  planType?: InputMaybe<Scalars['String']['input']>;
};


/** GraphQL Queries */
export type QueryLanguagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** GraphQL Queries */
export type QuerySayHealthNotesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


/** GraphQL Queries */
export type QuerySayHelloCtToolArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


/** GraphQL Queries */
export type QueryTimezonesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** GraphQL Queries */
export type QueryUnitOfMeasurementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Type of regions */
export enum Region {
  Eu = 'EU',
  Na = 'NA'
}

export type ServiceItem = {
  __typename?: 'ServiceItem';
  chargedAmount?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  patientResponsibilityAmount?: Maybe<Scalars['Float']['output']>;
  planAllowedAmount?: Maybe<Scalars['Float']['output']>;
  planPaidAmount?: Maybe<Scalars['Float']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type ServiceItems = {
  __typename?: 'ServiceItems';
  data?: Maybe<Array<Maybe<ServiceItem>>>;
};

/** Measurement data of sleep input */
export type SleepMeasurement = {
  /** Source App if known */
  app?: InputMaybe<Scalars['String']['input']>;
  /** Sleep spent in restless stage */
  awakeSeconds: Scalars['Int']['input'];
  deepSleepSeconds: Scalars['Int']['input'];
  /** Source Device if known */
  device?: InputMaybe<Scalars['String']['input']>;
  lightSleepSeconds: Scalars['Int']['input'];
  measurementType: SleepMeasurementType;
  /** Sleep seconds that couldn't be assigned to a particular stage */
  otherSleepSeconds: Scalars['Int']['input'];
  remSleepSeconds: Scalars['Int']['input'];
  /** Timestamp of measurement. E.g.: 1996-12-19T16:39:57-08:00 or 1996-12-19T16:39:57Z */
  timestamp: Scalars['DateTime']['input'];
  /** Is the measurement from a validated source? */
  validated: Scalars['Boolean']['input'];
};

/** Type of sleep measurement */
export enum SleepMeasurementType {
  CumulativeSleepSeconds = 'CUMULATIVE_SLEEP_SECONDS'
}

export type Sponsor = {
  __typename?: 'Sponsor';
  billingReference?: Maybe<Scalars['JSON']['output']>;
  coverPhotoUrl?: Maybe<Scalars['JSON']['output']>;
  demo?: Maybe<Scalars['JSON']['output']>;
  distributorId?: Maybe<Scalars['JSON']['output']>;
  guid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  industryId?: Maybe<Scalars['Float']['output']>;
  legacyId?: Maybe<Scalars['Float']['output']>;
  logoUrl?: Maybe<Scalars['JSON']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partners?: Maybe<Scalars['JSON']['output']>;
  pillarConfigurations?: Maybe<Scalars['JSON']['output']>;
  planType?: Maybe<Scalars['String']['output']>;
  productEdition?: Maybe<Scalars['String']['output']>;
  productLaunchDate?: Maybe<Scalars['JSON']['output']>;
  sponsorImageUrls?: Maybe<Scalars['JSON']['output']>;
  sponsorOnboardingItems?: Maybe<Scalars['JSON']['output']>;
  sponsorSettingsId?: Maybe<Scalars['Float']['output']>;
  sponsorSettingsUI?: Maybe<SponsorSettingsUi>;
  sponsorSupportEmailSettings?: Maybe<Scalars['JSON']['output']>;
  sponsorType?: Maybe<Scalars['String']['output']>;
  supportEmail?: Maybe<Scalars['JSON']['output']>;
  supportPageLink?: Maybe<Scalars['JSON']['output']>;
  supportPhone?: Maybe<Scalars['JSON']['output']>;
  themeId?: Maybe<Scalars['JSON']['output']>;
};

export type SponsorSettings = {
  __typename?: 'SponsorSettings';
  agreementsVersion?: Maybe<Scalars['String']['output']>;
  allowNonBinaryGender?: Maybe<Scalars['Boolean']['output']>;
  allowNonBinaryGenderInSurvey?: Maybe<Scalars['Boolean']['output']>;
  allowPhoneNumberCollection?: Maybe<Scalars['Boolean']['output']>;
  allowSendingEmployeeIdToPartners?: Maybe<Scalars['Boolean']['output']>;
  allowSyncBiometricData?: Maybe<Scalars['Boolean']['output']>;
  authenticationProvider?: Maybe<Scalars['String']['output']>;
  billingModel?: Maybe<Scalars['String']['output']>;
  biometricLogin?: Maybe<Scalars['Boolean']['output']>;
  brandedPlatformName?: Maybe<Scalars['String']['output']>;
  coachesCornerInternationalMode?: Maybe<Scalars['Boolean']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  customDeidentificationDuration?: Maybe<Scalars['Void']['output']>;
  customLogoutUrl?: Maybe<Scalars['Void']['output']>;
  deidentificationDuration?: Maybe<Scalars['Int']['output']>;
  devicePromo?: Maybe<Scalars['String']['output']>;
  disableMobileOfflineMode?: Maybe<Scalars['Boolean']['output']>;
  displayCovidVaccinationQuestion?: Maybe<Scalars['Boolean']['output']>;
  displayCovidVaccinationStatus?: Maybe<Scalars['Boolean']['output']>;
  displayCustomCompanyLabel?: Maybe<Scalars['Void']['output']>;
  displayMissingStepsLink?: Maybe<Scalars['Boolean']['output']>;
  displayReasonableAlternativeText?: Maybe<Scalars['Boolean']['output']>;
  enableBenefitsNavigation?: Maybe<Scalars['Boolean']['output']>;
  enableChallengeChat?: Maybe<Scalars['Boolean']['output']>;
  enableChatSupport?: Maybe<Scalars['Boolean']['output']>;
  enableDataRequestForm?: Maybe<Scalars['Boolean']['output']>;
  enableDefaultSuggestedTopics?: Maybe<Scalars['Boolean']['output']>;
  enableDeidentification?: Maybe<Scalars['Boolean']['output']>;
  enableDomainNavigation?: Maybe<Scalars['Boolean']['output']>;
  enableHomepageRedesign?: Maybe<Scalars['Boolean']['output']>;
  enableIdeasLabTab?: Maybe<Scalars['Boolean']['output']>;
  enableInviteColleagues?: Maybe<Scalars['Boolean']['output']>;
  enablePassportProduct?: Maybe<Scalars['Boolean']['output']>;
  enablePhotoUpload?: Maybe<Scalars['Boolean']['output']>;
  enableResponsiveNavbar?: Maybe<Scalars['Boolean']['output']>;
  enableTruncatedLegacyEnrollment?: Maybe<Scalars['Boolean']['output']>;
  enableVaccinationCard?: Maybe<Scalars['Boolean']['output']>;
  enableVouchers?: Maybe<Scalars['Boolean']['output']>;
  enableVpNavigate?: Maybe<Scalars['Boolean']['output']>;
  enrollmentRegion?: Maybe<Scalars['String']['output']>;
  enrollmentSearch?: Maybe<Scalars['String']['output']>;
  excludeMixpanelTracking?: Maybe<Scalars['Boolean']['output']>;
  externalReferenceId?: Maybe<Scalars['Void']['output']>;
  friendsModule?: Maybe<Scalars['Boolean']['output']>;
  genesisOnly?: Maybe<Scalars['Boolean']['output']>;
  hasAchEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasDonationsEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasGoalSettingModule?: Maybe<Scalars['Boolean']['output']>;
  hasGroupsModule?: Maybe<Scalars['Boolean']['output']>;
  hasHealthRiskBundle?: Maybe<Scalars['Boolean']['output']>;
  hasHub?: Maybe<Scalars['Boolean']['output']>;
  hasLiveServicesCoaching?: Maybe<Scalars['Boolean']['output']>;
  hasManualWorkoutRewards?: Maybe<Scalars['Boolean']['output']>;
  hasPromotedTrackerChallenges?: Maybe<Scalars['Boolean']['output']>;
  hasRewardFlyoutEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasSleepModule?: Maybe<Scalars['Boolean']['output']>;
  hasSupporterModule?: Maybe<Scalars['Boolean']['output']>;
  hasTrackerChallenges?: Maybe<Scalars['Boolean']['output']>;
  hideFooterLogo?: Maybe<Scalars['Boolean']['output']>;
  hideNavigationBar?: Maybe<Scalars['Boolean']['output']>;
  hraMigrated?: Maybe<Scalars['Boolean']['output']>;
  hraPreferredName?: Maybe<Scalars['String']['output']>;
  hraPreferredNameVM?: Maybe<HraPreferredNameVm>;
  hraProviderType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  joinUrl?: Maybe<Scalars['Void']['output']>;
  loginRedirectDomain?: Maybe<Scalars['Void']['output']>;
  loginRedirectUser?: Maybe<Scalars['Void']['output']>;
  logoutAfterClosingTab?: Maybe<Scalars['Boolean']['output']>;
  manuallyEnteredAlwaysValidated?: Maybe<Scalars['Boolean']['output']>;
  maritzType?: Maybe<Scalars['String']['output']>;
  mccRewardsMethod?: Maybe<Scalars['String']['output']>;
  memberCancellationDuration?: Maybe<Scalars['Int']['output']>;
  memberDataRegion?: Maybe<Scalars['String']['output']>;
  navigateEndDate?: Maybe<Scalars['Void']['output']>;
  navigateLaunchDate?: Maybe<Scalars['Void']['output']>;
  offPlatformRedemptionEnabled?: Maybe<Scalars['Boolean']['output']>;
  onPlatformRedemptionDetails?: Maybe<Scalars['Boolean']['output']>;
  onboardingChallengeBlocker?: Maybe<Scalars['Void']['output']>;
  passportBillingMethod?: Maybe<Scalars['Void']['output']>;
  passportEndDate?: Maybe<Scalars['Void']['output']>;
  passportFastTrackDuration?: Maybe<Scalars['Void']['output']>;
  passportLaunchDate?: Maybe<Scalars['Void']['output']>;
  passwordProvider?: Maybe<Scalars['String']['output']>;
  passwordResetChallenge?: Maybe<Scalars['String']['output']>;
  phoneNumberCollectionAllowed?: Maybe<Scalars['Boolean']['output']>;
  promptForPhoneNumber?: Maybe<Scalars['Boolean']['output']>;
  redemptionProviderAccount?: Maybe<Scalars['Void']['output']>;
  redemptionProviderCustomer?: Maybe<Scalars['Void']['output']>;
  rejectExpiredSamlAssertion?: Maybe<Scalars['Boolean']['output']>;
  resetCompletedJourneys?: Maybe<Scalars['Boolean']['output']>;
  rewardsMigrated?: Maybe<Scalars['Boolean']['output']>;
  rewardsMigratedActive?: Maybe<Scalars['Boolean']['output']>;
  rewardsMigratedDate?: Maybe<Scalars['String']['output']>;
  rewardsProcessBothPlatforms?: Maybe<Scalars['Boolean']['output']>;
  rewardsRedemptionProvider?: Maybe<Scalars['String']['output']>;
  sourceCompany?: Maybe<Scalars['String']['output']>;
  sponsorStatus?: Maybe<Scalars['String']['output']>;
  spouseDetailViewEnabled?: Maybe<Scalars['Boolean']['output']>;
  ssoIdpIdentifier?: Maybe<Scalars['Void']['output']>;
  ssoRedirectUrl?: Maybe<Scalars['Void']['output']>;
  ssoSegment?: Maybe<Scalars['Void']['output']>;
  ssoSponsorAlias?: Maybe<Scalars['Void']['output']>;
  supportDepartment?: Maybe<Scalars['String']['output']>;
  supportPhoneDisabled?: Maybe<Scalars['Boolean']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
  updatedDate?: Maybe<Scalars['DateTime']['output']>;
  usernameOnlyLogin?: Maybe<Scalars['Boolean']['output']>;
};

export type SponsorSettingsBenefits = {
  __typename?: 'SponsorSettingsBenefits';
  enableVpNavigate: Scalars['Boolean']['output'];
  hasHub: Scalars['Boolean']['output'];
  navigateEndDate?: Maybe<Scalars['Date']['output']>;
  navigateLaunchDate?: Maybe<Scalars['Date']['output']>;
};

export type SponsorSettingsUi = {
  __typename?: 'SponsorSettingsUI';
  createdDate?: Maybe<Scalars['String']['output']>;
  disableClientAdminUI?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sponsorId?: Maybe<Scalars['Float']['output']>;
  updatedDate?: Maybe<Scalars['String']['output']>;
};

export type SponsorSettingsUiBenefits = {
  __typename?: 'SponsorSettingsUiBenefits';
  disableClientAdminUi: Scalars['Boolean']['output'];
  navigationLayout: Scalars['String']['output'];
};

/** Type that describes the timezone object */
export type TimezoneDescriptor = Node & {
  __typename?: 'TimezoneDescriptor';
  /** Timezone description */
  description?: Maybe<Scalars['String']['output']>;
  /** Timezone id */
  id: Scalars['ID']['output'];
  /** Timezone name */
  name: Scalars['String']['output'];
};

/** A connection to a list of items */
export type TimezoneDescriptorConnection = {
  __typename?: 'TimezoneDescriptorConnection';
  /** A list of edges */
  edges: Array<TimezoneDescriptorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection */
export type TimezoneDescriptorEdge = {
  __typename?: 'TimezoneDescriptorEdge';
  /** A Cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: TimezoneDescriptor;
};

export type TpaSponsorSettings = {
  __typename?: 'TpaSponsorSettings';
  hasTpaServices: Scalars['Boolean']['output'];
  publicUrl?: Maybe<Scalars['String']['output']>;
  tpaEndDate?: Maybe<Scalars['Date']['output']>;
  tpaLaunchDate?: Maybe<Scalars['Date']['output']>;
  transparencyVendor?: Maybe<Scalars['String']['output']>;
};

export type Trophy = {
  __typename?: 'Trophy';
  achievement?: Maybe<Query_Trophies_Items_Achievement>;
  catchPhrase?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  customRewardValue?: Maybe<Scalars['String']['output']>;
  earned?: Maybe<Scalars['Boolean']['output']>;
  entityId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  intervalType?: Maybe<Scalars['String']['output']>;
  lastEarnedDate?: Maybe<Scalars['String']['output']>;
  mobileUrl?: Maybe<Scalars['URL']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  rewardValue?: Maybe<Scalars['Int']['output']>;
  rewardableActionName?: Maybe<Scalars['String']['output']>;
  rewardableActionType?: Maybe<Scalars['String']['output']>;
  rewards?: Maybe<Array<Maybe<Query_Trophies_Items_Rewards_Items>>>;
  secondaryThreshold?: Maybe<Scalars['Int']['output']>;
  threshold?: Maybe<Scalars['Int']['output']>;
  timesEarned?: Maybe<Scalars['Int']['output']>;
  timesRewardable?: Maybe<Scalars['Int']['output']>;
  totalEventcode?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['URL']['output']>;
};

/** Types of unit measurements */
export enum UnitOfMeasurement {
  Imperial = 'IMPERIAL',
  Metric = 'METRIC',
  UkImperial = 'UK_IMPERIAL'
}

/** Type that describes the unit of measurement object */
export type UnitOfMeasurementDescriptor = Node & {
  __typename?: 'UnitOfMeasurementDescriptor';
  /** Unit of measurement description */
  description?: Maybe<Scalars['String']['output']>;
  /** Unit of measurement name */
  id: Scalars['ID']['output'];
};

/** A connection to a list of items */
export type UnitOfMeasurementDescriptorConnection = {
  __typename?: 'UnitOfMeasurementDescriptorConnection';
  /** A list of edges */
  edges: Array<UnitOfMeasurementDescriptorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection */
export type UnitOfMeasurementDescriptorEdge = {
  __typename?: 'UnitOfMeasurementDescriptorEdge';
  /** A Cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: UnitOfMeasurementDescriptor;
};

/** Input for creating or updating activity data */
export type UpdateActivityInput = {
  clientMutationId: Scalars['String']['input'];
  measurements: Array<ActivityMeasurement>;
  provider: ActivityProvider;
  vendor: ActivityVendor;
};

/** Input for creating or updating metrics */
export type UpdateMetricInput = {
  /** The category of workout being sent */
  category?: InputMaybe<Scalars['String']['input']>;
  /** Client created identifier for this event. Must be unique per mutation. */
  clientMutationId: Scalars['String']['input'];
  createdAt: Scalars['DateTime']['input'];
  /** The end time of the event represented in UTC. E.g.: 1996-12-19T16:39:57Z */
  endTime: Scalars['DateTime']['input'];
  metrics: Array<Metric>;
  /** The source of the UTC offset */
  offsetOrigin: Scalars['String']['input'];
  source: MetricSource;
  /** The start time of the event represented in UTC. E.g.: 1996-12-19T16:39:57Z */
  startTime: Scalars['DateTime']['input'];
  /** The type of metric update */
  type: Scalars['String']['input'];
  /** The offset in seconds from UTC */
  utcOffset: Scalars['Long']['input'];
  /** Where the client got this data from. */
  vendor: Scalars['String']['input'];
};

/** Type that describes the profile object */
export type UpdateProfileInput = {
  /** Member bio */
  bio?: InputMaybe<Scalars['String']['input']>;
  /** Member country id */
  countryId?: InputMaybe<Scalars['Int']['input']>;
  /** Member date of birth */
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  /** Member display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Member email address */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  /** Member first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Member gender identity */
  genderIdentity?: InputMaybe<GenderIdentity>;
  /** Home phone number */
  homePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Member language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Member last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Mobile phone number */
  mobilePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Member picture url */
  picture?: InputMaybe<Scalars['String']['input']>;
  /** Member timezone name */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** Member unit of measurement */
  unitOfMeasurement?: InputMaybe<UnitOfMeasurement>;
};

export type UpdateProfileResponse = Profile | ValidationResult;

/** Input for creating or updating sleep data */
export type UpdateSleepInput = {
  clientMutationId: Scalars['String']['input'];
  measurements: Array<SleepMeasurement>;
  provider: ActivityProvider;
  vendor: ActivityVendor;
};

/** Validation result */
export type ValidationResult = {
  __typename?: 'ValidationResult';
  /** An error code */
  errorCode?: Maybe<Scalars['String']['output']>;
  /** An error message */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** A list of field errors */
  errors: Array<FieldError>;
};

export type Query_Trophies_Items_Achievement = {
  __typename?: 'query_trophies_items_achievement';
  imageUrl?: Maybe<Scalars['URL']['output']>;
  unearnedImageUrl?: Maybe<Scalars['URL']['output']>;
  unitsLabel?: Maybe<Scalars['String']['output']>;
};

export type Query_Trophies_Items_Rewards_Items = {
  __typename?: 'query_trophies_items_rewards_items';
  rewardTypeDisplay?: Maybe<Scalars['String']['output']>;
  valueDisplay?: Maybe<Scalars['String']['output']>;
};

export type BenefitPagesSponsorSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type BenefitPagesSponsorSettingsQuery = { __typename?: 'Query', benefitPagesSponsorSettings?: { __typename?: 'BenefitPagesSponsorSettings', isNavigateExperience: boolean, isTpaEnabled: boolean, benefitsSponsorSetting: { __typename?: 'BenefitSponsorSetting', shouldOverwriteBenefitsText: boolean, shouldDisplayRecommended: boolean }, benefitsPagesSetting?: { __typename?: 'BenefitsPagesSetting', benefitsPage?: { __typename?: 'BenefitsPageLayout', pageTitle: string } | null, programsPage?: { __typename?: 'BenefitsPageLayout', pageTitle: string } | null } | null, sponsorSettingsUi: { __typename?: 'SponsorSettingsUiBenefits', navigationLayout: string } } | null };

export type InsurancePlanAccumulatorsQueryVariables = Exact<{
  planType?: InputMaybe<Scalars['String']['input']>;
}>;


export type InsurancePlanAccumulatorsQuery = { __typename?: 'Query', insurancePlanAccumulators?: { __typename?: 'InsurancePlanAccumulators', planId: number, planLevel: string, lastUpdate: any, accumulatorData: Array<{ __typename?: 'InsurancePlanAccumulatorsCoverage', applied?: string | null, coverage: string, level: string, limit?: string | null, network: string, progress: number, remaining?: string | null } | null> } | null };

export type InsurancePlanByTypeQueryVariables = Exact<{
  planType?: InputMaybe<Scalars['String']['input']>;
}>;


export type InsurancePlanByTypeQuery = { __typename?: 'Query', insurancePlan?: { __typename?: 'InsurancePlan', id?: number | null, name?: string | null, imageUrl?: string | null, longDescription?: string | null, shortDescription?: string | null, phoneNumber?: string | null, websiteUrl?: string | null, type?: InsurancePlanType | null, subscriber?: { __typename?: 'InsurancePlanMember', active?: boolean | null, dateOfBirth?: any | null, effectiveEndDate?: any | null, effectiveStartDate?: any | null, enterprisePersonId?: string | null, firstName?: string | null, lastName?: string | null, primarySubscriber?: boolean | null, subscriberId?: string | null, consent?: string | null } | null, attachments?: Array<{ __typename?: 'InsurancePlanAttachment', format?: string | null, linkUrl?: string | null, sortOrder?: number | null, title?: string | null, type?: string | null } | null> | null, coverage?: { __typename?: 'InsurancePlanCoverage', showAccumulators: string, type: string, amounts: Array<{ __typename?: 'InsurancePlanCoverageAmount', id: number, amount?: number | null, network: string, planLevel: string, coverage: string } | null> } | null } | null, insurancePlanMembers?: { __typename?: 'InsurancePlanMemberConnection', data?: Array<{ __typename?: 'InsurancePlanMember', firstName?: string | null, lastName?: string | null, dateOfBirth?: any | null, effectiveEndDate?: any | null, effectiveStartDate?: any | null, primarySubscriber?: boolean | null, enterprisePersonId?: string | null, active?: boolean | null, imageUrl?: string | null, subscriberId?: string | null } | null> | null } | null };

export type InsurancePlansQueryVariables = Exact<{ [key: string]: never; }>;


export type InsurancePlansQuery = { __typename?: 'Query', insurancePlans?: { __typename?: 'InsurancePlanConnection', data?: Array<{ __typename?: 'InsurancePlan', id?: number | null, name?: string | null, imageUrl?: string | null, longDescription?: string | null, shortDescription?: string | null, phoneNumber?: string | null, websiteUrl?: string | null, type?: InsurancePlanType | null, subscriber?: { __typename?: 'InsurancePlanMember', active?: boolean | null, dateOfBirth?: any | null, effectiveEndDate?: any | null, effectiveStartDate?: any | null, enterprisePersonId?: string | null, firstName?: string | null, lastName?: string | null, primarySubscriber?: boolean | null, subscriberId?: string | null, consent?: string | null } | null, attachments?: Array<{ __typename?: 'InsurancePlanAttachment', format?: string | null, linkUrl?: string | null, sortOrder?: number | null, title?: string | null, type?: string | null } | null> | null, coverage?: { __typename?: 'InsurancePlanCoverage', type: string, showAccumulators: string, amounts: Array<{ __typename?: 'InsurancePlanCoverageAmount', id: number, planLevel: string, network: string, coverage: string, amount?: number | null } | null> } | null } | null> | null } | null };

export type MemberBenefitsFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type MemberBenefitsFeaturesQuery = { __typename?: 'Query', memberBenefitsFeatures?: { __typename?: 'MemberBenefitsFeatures', findCareFeatures: { __typename?: 'FindCareFeatures', hasFindCare?: boolean | null, hasFindCareOnlineDoctor?: boolean | null }, insurancePlanFeatures: { __typename?: 'InsurancePlanFeatures', hasIdCards: boolean, hasInsuranceClaims: boolean } } | null };

export type HomepageBenefitPagesSponsorSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type HomepageBenefitPagesSponsorSettingsQuery = { __typename?: 'Query', benefitPagesSponsorSettings?: { __typename?: 'BenefitPagesSponsorSettings', benefitsSponsorSetting: { __typename?: 'BenefitSponsorSetting', shouldOverwriteBenefitsText: boolean } } | null };

export type GendersQueryQueryVariables = Exact<{
  withNonBinary?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GendersQueryQuery = { __typename?: 'Query', genders: { __typename?: 'GenderIdentityDescriptorConnection', edges: Array<{ __typename?: 'GenderIdentityDescriptorEdge', node: { __typename?: 'GenderIdentityDescriptor', id: string, description?: string | null } }> } };

export type InsuranceClaimsQueryVariables = Exact<{ [key: string]: never; }>;


export type InsuranceClaimsQuery = { __typename?: 'Query', insuranceClaims?: { __typename?: 'ClaimConnection', edges?: Array<{ __typename?: 'ClaimEdge', node?: { __typename?: 'Claim', id: string, claimNumber: string, patientFirstName: string, patientLastName: string, patientResponsibilityAmount?: number | null, totalChargedAmount?: number | null, serviceStartDate?: any | null, provider?: { __typename?: 'ClaimProvider', name?: string | null } | null } | null } | null> | null } | null };

export type InsurancePlanAccumulatorsDataQueryVariables = Exact<{ [key: string]: never; }>;


export type InsurancePlanAccumulatorsDataQuery = { __typename?: 'Query', insurancePlanAccumulators?: { __typename?: 'InsurancePlanAccumulators', planId: number, planLevel: string, lastUpdate: any, accumulatorData: Array<{ __typename?: 'InsurancePlanAccumulatorsCoverage', level: string, coverage: string, applied?: string | null, remaining?: string | null, limit?: string | null, progress: number, network: string } | null> } | null };

export type MedicalInsurancePlanQueryVariables = Exact<{ [key: string]: never; }>;


export type MedicalInsurancePlanQuery = { __typename?: 'Query', insurancePlan?: { __typename?: 'InsurancePlan', name?: string | null, subscriber?: { __typename?: 'InsurancePlanMember', effectiveStartDate?: any | null, subscriberId?: string | null } | null, coverage?: { __typename?: 'InsurancePlanCoverage', showAccumulators: string, type: string, amounts: Array<{ __typename?: 'InsurancePlanCoverageAmount', id: number, planLevel: string, network: string, coverage: string, amount?: number | null } | null> } | null } | null };

export type ProfileQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQueryQuery = { __typename?: 'Query', profile?: { __typename?: 'Profile', id: string, memberId: string, personId: string, sponsorId?: string | null, externalId?: string | null, enterprisePersonId?: string | null, unitOfMeasurement?: UnitOfMeasurement | null, genderIdentity?: GenderIdentity | null, emailAddress?: string | null, username?: string | null, firstName?: string | null, lastName?: string | null, displayName?: string | null, dateOfBirth?: any | null, enrollmentDate?: any | null, picture?: string | null, bio?: string | null, language?: { __typename?: 'LanguageDescriptor', id: string, code?: string | null, description?: string | null } | null, timezone?: { __typename?: 'TimezoneDescriptor', id: string, name: string, description?: string | null } | null, country?: { __typename?: 'Country', id: string, name: string, phoneCode?: string | null, code?: string | null } | null, phoneSettings?: { __typename?: 'PhoneSettings', mobileNumber?: string | null, mobileNumberVerified?: boolean | null, mobileNumberIsoCode?: string | null, homeNumber?: string | null, homeNumberIsoCode?: string | null } | null } | null };

export type StaticDataQueryQueryVariables = Exact<{
  withNonBinary?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type StaticDataQueryQuery = { __typename?: 'Query', countries: { __typename?: 'CountryConnection', edges: Array<{ __typename?: 'CountryEdge', node: { __typename?: 'Country', id: string, code?: string | null, name: string, cookiePolicy: boolean, storeUrl?: string | null, phoneCode?: string | null } }> }, languages: { __typename?: 'LanguageDescriptorConnection', edges: Array<{ __typename?: 'LanguageDescriptorEdge', node: { __typename?: 'LanguageDescriptor', id: string, code?: string | null, description?: string | null, englishNative?: string | null, selectable?: boolean | null } }> }, timezones: { __typename?: 'TimezoneDescriptorConnection', edges: Array<{ __typename?: 'TimezoneDescriptorEdge', node: { __typename?: 'TimezoneDescriptor', id: string, name: string, description?: string | null } }> }, unitOfMeasurements: { __typename?: 'UnitOfMeasurementDescriptorConnection', edges: Array<{ __typename?: 'UnitOfMeasurementDescriptorEdge', node: { __typename?: 'UnitOfMeasurementDescriptor', id: string, description?: string | null } }> }, genders: { __typename?: 'GenderIdentityDescriptorConnection', edges: Array<{ __typename?: 'GenderIdentityDescriptorEdge', node: { __typename?: 'GenderIdentityDescriptor', id: string, description?: string | null, genderCode?: string | null, binary?: boolean | null } }> } };

export type UpdateProfileMutationVariables = Exact<{
  input?: InputMaybe<UpdateProfileInput>;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile?: { __typename: 'Profile', id: string, memberId: string, personId: string, sponsorId?: string | null, externalId?: string | null, enterprisePersonId?: string | null, unitOfMeasurement?: UnitOfMeasurement | null, genderIdentity?: GenderIdentity | null, emailAddress?: string | null, username?: string | null, firstName?: string | null, lastName?: string | null, displayName?: string | null, dateOfBirth?: any | null, enrollmentDate?: any | null, picture?: string | null, bio?: string | null, language?: { __typename?: 'LanguageDescriptor', id: string, code?: string | null, description?: string | null } | null, timezone?: { __typename?: 'TimezoneDescriptor', id: string, name: string, description?: string | null } | null, country?: { __typename?: 'Country', id: string, name: string, phoneCode?: string | null, code?: string | null } | null, phoneSettings?: { __typename?: 'PhoneSettings', mobileNumber?: string | null, mobileNumberVerified?: boolean | null, mobileNumberIsoCode?: string | null, homeNumber?: string | null, homeNumberIsoCode?: string | null } | null } | { __typename: 'ValidationResult', errorMessage?: string | null, errors: Array<{ __typename?: 'FieldError', field?: string | null, errorCode?: string | null, errorMessage?: string | null }> } | null };

export const BenefitPagesSponsorSettingsDocument = gql`
    query BenefitPagesSponsorSettings {
  benefitPagesSponsorSettings {
    benefitsSponsorSetting {
      shouldOverwriteBenefitsText
      shouldDisplayRecommended
    }
    benefitsPagesSetting {
      benefitsPage {
        pageTitle
      }
      programsPage {
        pageTitle
      }
    }
    sponsorSettingsUi {
      navigationLayout
    }
    isNavigateExperience
    isTpaEnabled
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BenefitPagesSponsorSettingsGQL extends Apollo.Query<BenefitPagesSponsorSettingsQuery, BenefitPagesSponsorSettingsQueryVariables> {
    document = BenefitPagesSponsorSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsurancePlanAccumulatorsDocument = gql`
    query InsurancePlanAccumulators($planType: String) {
  insurancePlanAccumulators(planType: $planType) {
    planId
    planLevel
    lastUpdate
    accumulatorData {
      applied
      coverage
      level
      limit
      network
      progress
      remaining
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsurancePlanAccumulatorsGQL extends Apollo.Query<InsurancePlanAccumulatorsQuery, InsurancePlanAccumulatorsQueryVariables> {
    document = InsurancePlanAccumulatorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsurancePlanByTypeDocument = gql`
    query insurancePlanByType($planType: String) {
  insurancePlan(planType: $planType) {
    id
    name
    imageUrl
    longDescription
    shortDescription
    phoneNumber
    websiteUrl
    type
    subscriber {
      active
      dateOfBirth
      effectiveEndDate
      effectiveStartDate
      enterprisePersonId
      firstName
      lastName
      primarySubscriber
      subscriberId
      consent
    }
    attachments {
      format
      linkUrl
      sortOrder
      title
      type
    }
    coverage {
      amounts {
        id
        amount
        network
        planLevel
        coverage
      }
      showAccumulators
      type
    }
  }
  insurancePlanMembers(planType: $planType) {
    data {
      firstName
      lastName
      dateOfBirth
      effectiveEndDate
      effectiveStartDate
      primarySubscriber
      enterprisePersonId
      active
      imageUrl
      subscriberId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsurancePlanByTypeGQL extends Apollo.Query<InsurancePlanByTypeQuery, InsurancePlanByTypeQueryVariables> {
    document = InsurancePlanByTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsurancePlansDocument = gql`
    query InsurancePlans {
  insurancePlans {
    data {
      id
      name
      imageUrl
      longDescription
      shortDescription
      phoneNumber
      websiteUrl
      type
      subscriber {
        active
        dateOfBirth
        effectiveEndDate
        effectiveStartDate
        enterprisePersonId
        firstName
        lastName
        primarySubscriber
        subscriberId
        consent
      }
      attachments {
        format
        linkUrl
        sortOrder
        title
        type
      }
      coverage {
        amounts {
          id
          planLevel
          network
          coverage
          amount
        }
        type
        showAccumulators
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsurancePlansGQL extends Apollo.Query<InsurancePlansQuery, InsurancePlansQueryVariables> {
    document = InsurancePlansDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MemberBenefitsFeaturesDocument = gql`
    query memberBenefitsFeatures {
  memberBenefitsFeatures {
    findCareFeatures {
      hasFindCare
      hasFindCareOnlineDoctor
    }
    insurancePlanFeatures {
      hasIdCards
      hasInsuranceClaims
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MemberBenefitsFeaturesGQL extends Apollo.Query<MemberBenefitsFeaturesQuery, MemberBenefitsFeaturesQueryVariables> {
    document = MemberBenefitsFeaturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HomepageBenefitPagesSponsorSettingsDocument = gql`
    query HomepageBenefitPagesSponsorSettings {
  benefitPagesSponsorSettings {
    benefitsSponsorSetting {
      shouldOverwriteBenefitsText
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HomepageBenefitPagesSponsorSettingsGQL extends Apollo.Query<HomepageBenefitPagesSponsorSettingsQuery, HomepageBenefitPagesSponsorSettingsQueryVariables> {
    document = HomepageBenefitPagesSponsorSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GendersQueryDocument = gql`
    query GendersQuery($withNonBinary: Boolean = false) {
  genders(withNonBinary: $withNonBinary) {
    edges {
      node {
        id
        description
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GendersQueryGQL extends Apollo.Query<GendersQueryQuery, GendersQueryQueryVariables> {
    document = GendersQueryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsuranceClaimsDocument = gql`
    query insuranceClaims {
  insuranceClaims(first: 2) {
    edges {
      node {
        id
        claimNumber
        patientFirstName
        patientLastName
        patientResponsibilityAmount
        totalChargedAmount
        provider {
          name
        }
        serviceStartDate
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsuranceClaimsGQL extends Apollo.Query<InsuranceClaimsQuery, InsuranceClaimsQueryVariables> {
    document = InsuranceClaimsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsurancePlanAccumulatorsDataDocument = gql`
    query InsurancePlanAccumulatorsData {
  insurancePlanAccumulators(planType: "Medical") {
    planId
    planLevel
    lastUpdate
    accumulatorData {
      level
      coverage
      applied
      remaining
      limit
      progress
      network
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsurancePlanAccumulatorsDataGQL extends Apollo.Query<InsurancePlanAccumulatorsDataQuery, InsurancePlanAccumulatorsDataQueryVariables> {
    document = InsurancePlanAccumulatorsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MedicalInsurancePlanDocument = gql`
    query MedicalInsurancePlan {
  insurancePlan(planType: "Medical") {
    name
    subscriber {
      effectiveStartDate
      subscriberId
    }
    coverage {
      showAccumulators
      amounts {
        id
        planLevel
        network
        coverage
        amount
      }
      type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MedicalInsurancePlanGQL extends Apollo.Query<MedicalInsurancePlanQuery, MedicalInsurancePlanQueryVariables> {
    document = MedicalInsurancePlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileQueryDocument = gql`
    query ProfileQuery {
  profile {
    id
    memberId
    personId
    sponsorId
    externalId
    enterprisePersonId
    unitOfMeasurement
    genderIdentity
    emailAddress
    username
    firstName
    lastName
    displayName
    dateOfBirth
    enrollmentDate
    picture
    bio
    language {
      id
      code
      description
    }
    timezone {
      id
      name
      description
    }
    country {
      id
      name
      phoneCode
      code
    }
    phoneSettings {
      mobileNumber
      mobileNumberVerified
      mobileNumberIsoCode
      homeNumber
      homeNumberIsoCode
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileQueryGQL extends Apollo.Query<ProfileQueryQuery, ProfileQueryQueryVariables> {
    document = ProfileQueryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StaticDataQueryDocument = gql`
    query StaticDataQuery($withNonBinary: Boolean = false) {
  countries {
    edges {
      node {
        id
        code
        name
        cookiePolicy
        storeUrl
        phoneCode
      }
    }
  }
  languages {
    edges {
      node {
        id
        code
        description
        englishNative
        selectable
      }
    }
  }
  timezones {
    edges {
      node {
        id
        name
        description
      }
    }
  }
  unitOfMeasurements {
    edges {
      node {
        id
        description
      }
    }
  }
  genders(withNonBinary: $withNonBinary) {
    edges {
      node {
        id
        description
        genderCode
        binary
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StaticDataQueryGQL extends Apollo.Query<StaticDataQueryQuery, StaticDataQueryQueryVariables> {
    document = StaticDataQueryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($input: UpdateProfileInput) {
  updateProfile(input: $input) {
    ... on ValidationResult {
      __typename
      errorMessage
      errors {
        field
        errorCode
        errorMessage
      }
    }
    ... on Profile {
      __typename
      id
      memberId
      personId
      sponsorId
      externalId
      enterprisePersonId
      unitOfMeasurement
      genderIdentity
      emailAddress
      username
      firstName
      lastName
      displayName
      dateOfBirth
      enrollmentDate
      picture
      bio
      language {
        id
        code
        description
      }
      timezone {
        id
        name
        description
      }
      country {
        id
        name
        phoneCode
        code
      }
      phoneSettings {
        mobileNumber
        mobileNumberVerified
        mobileNumberIsoCode
        homeNumber
        homeNumberIsoCode
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProfileGQL extends Apollo.Mutation<UpdateProfileMutation, UpdateProfileMutationVariables> {
    document = UpdateProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }