import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilestackService } from '@filestack/angular';
import { saveAs } from 'file-saver';
import { Client, ClientOptions, PickerFileMetadata, PickerOptions, Security } from 'filestack-js';
import { PickerStoreOptions, PickerTransformationOptions } from 'filestack-js/build/main/lib/picker';
import { interval, Observable, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { ConfigurationService } from './configuration.service';
import { UserService } from './user.service';
import { PersistentCache } from '../core';

@Injectable({
    providedIn: 'root'
})
export class FilePickerService {
    readonly constants = {
        SEVEN_HUNDRED: 700,
        NUM_OF_SECONDS: 30,
        NUM_OF_MS_IN_SECOND: 1000,
        LANGUAGE_CODE_STRING_LENGTH: 2,
        URL_PATH: '/api/file/'
    };
    private sessionCache = new PersistentCache<Security>('@app:filestack-cache', 3600);
    readSecurityData = { policy: '', signature: '' };
    private readExpiry = 0;
    private member: any;
    private filestackClient = {} as Client;
    private observableRef = new Subscription();
    private downloadClient = this.fileStackService.init(this.configurationService.getFilePickerAPI());

    constructor(
        private fileStackService: FilestackService,
        private configurationService: ConfigurationService,
        private httpClient: HttpClient,
        private userService: UserService
    ) {
        this.userService.authenticate().subscribe(() => {
            this.member = this.userService.getCurrentUser();
        });
        const filePickerSecurityData = this.sessionCache.getItem('filePickerSecurityData');
        if (filePickerSecurityData) {
            this.initReadSecurityData(filePickerSecurityData.value);
        }

    }

    initReadSecurityData(readSecurityData: Security) {
        this.readSecurityData = readSecurityData;
        this.readExpiry = this.parseJsonPolicy(readSecurityData);
        this.sessionCache.setItem('filePickerSecurityData', readSecurityData);
    }

    getDefaultPickerOptions(
        language?: string,
        componentName?: string,
        acceptTypes: string[] = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'],
        maxSize?: number
    ): PickerOptions {
        let lang = 'en';
        if (language) {
            lang = language.substring(0, this.constants.LANGUAGE_CODE_STRING_LENGTH);
        }

        const storageOptions: PickerStoreOptions = {
            location: 's3',
            access: 'private',
            region: 'us-east-1',
            path: this.getBaseFileStorePath(componentName),
            container: this.configurationService.getFilePickerContainer()
        };
        return {
            accept: acceptTypes,
            customSourcePath: this.getBaseFileStorePath(componentName),
            lang: lang,
            storeTo: storageOptions,
            fromSources: ['local_file_system', 'url'],
            maxSize: maxSize,
            cleanupImageExif: {
                keepOrientation: true
            }
        } as PickerOptions;
    }

    getBaseFileStorePath(componentName?: string) {
        let path = this.configurationService.getS3() + '/';
        if (componentName) {
            path += componentName + '/';
        }
        return path;
    }

    //methodToCallBackTo is optional, and will pass the array of url's to the function given.
    upload(options: PickerOptions, methodToCallBackTo?: any, cancelMethodToCallBackTo?: any) {
        this.checkReadSecurityExpiry();
        this.initUploadSecurityData(this.readSecurityData);

        options.onOpen = () => {
            this.getUploadSecurityData().subscribe((response) => {
                this.filestackClient.setSecurity(response);
            });
            this.observableRef = interval(116000)
                .subscribe(() => {
                    this.getUploadSecurityData().subscribe((response) => {
                        this.filestackClient.setSecurity(response);
                    });
                });
        };

        options.onFileSelected = async(file) => {
            if (['url', 'local_file_system'].includes(file.source)) {
                const fileExtension = this.getExtension(file.filename);
                return { ...file, filename: `${uuidv4()}.${fileExtension}` };
            }
            return;
        };

        options.onUploadDone = (res) => {
            const urls: PickerFileMetadata[] = [];
            res.filesUploaded.forEach((metadata) => {
                urls.push(FilePickerService.validateImageUrl(metadata));
            });
            res.filesFailed.forEach((metadata) => {
                if (metadata.handle != null) {
                    this.delete(metadata.handle);
                }
            });
            this.observableRef.unsubscribe();
            methodToCallBackTo(urls);
        };
        options.onClose = () => {
            this.observableRef.unsubscribe();
            if (cancelMethodToCallBackTo) {
                cancelMethodToCallBackTo();
            }
        };
        options.onCancel = () => {
            this.observableRef.unsubscribe();
            if (cancelMethodToCallBackTo) {
                cancelMethodToCallBackTo();
            }
        };
        options.onFileUploadFailed = () => {
            this.observableRef.unsubscribe();
            if (cancelMethodToCallBackTo) {
                cancelMethodToCallBackTo();
            }
        };

        options.storeTo = {
            workflows: [this.configurationService.getFilePickerVirusDetectWorkflowId()]
        };
        this.filestackClient.picker(options).open().then();
    }

    uploadAdmin(pickerOptions: PickerOptions, methodForCallBackTo?: any, cancelMethodForCallBackTo?: any) {
        this.checkAdminReadSecurityExpiry();
        this.initUploadSecurityData(this.readSecurityData);

        pickerOptions.onOpen = () => {
            this.getAdminUploadSecurityData().subscribe((result) => {
                this.filestackClient.setSecurity(result);
            });
            this.observableRef = interval(116000)
                .subscribe(() => {
                    this.getAdminUploadSecurityData().subscribe((result) => {
                        this.filestackClient.setSecurity(result);
                    });
                });
        };

        pickerOptions.onFileSelected = async(files) => {
            if (['url', 'local_file_system'].includes(files.source)) {
                const filesExtension = this.getExtension(files.filename);
                return { ...files, filename: `${uuidv4()}.${filesExtension}` };
            }
            return;
        };

        pickerOptions.onUploadDone = (result) => {
            const url: PickerFileMetadata[] = [];
            result.filesUploaded.forEach((fileMetadata) => {
                url.push(FilePickerService.validateImageUrl(fileMetadata));
            });
            result.filesFailed.forEach((fileMetadata) => {
                if (fileMetadata.handle != null) {
                    this.delete(fileMetadata.handle);
                }
            });
            this.observableRef.unsubscribe();
            methodForCallBackTo(url);
        };
        pickerOptions.onClose = () => {
            this.observableRef.unsubscribe();
            if (cancelMethodForCallBackTo) {
                cancelMethodForCallBackTo();
            }
        };
        pickerOptions.onCancel = () => {
            this.observableRef.unsubscribe();
            if (cancelMethodForCallBackTo) {
                cancelMethodForCallBackTo();
            }
        };
        pickerOptions.onFileUploadFailed = () => {
            this.observableRef.unsubscribe();
            if (cancelMethodForCallBackTo) {
                cancelMethodForCallBackTo();
            }
        };

        pickerOptions.storeTo = {
            workflows: [this.configurationService.getFilePickerVirusDetectWorkflowId()]
        };
        this.filestackClient.picker(pickerOptions).open().then();
    }

    getImageSecurityData(handle?: string) {
        let params = new HttpParams();
        if (handle) {
            params = params.set('handle', handle);
        }
        params = params.set('readOnly', true);
        return this.httpClient.get<Security>(`/api/members/${this.member.id}/filestack-security/platform`, {
            params
        });
    }
    getAdminImageSecurityData(handle?: string) {
        let params = new HttpParams();
        if (handle) {
            params = params.set('handle', handle);
        }
        params = params.set('readOnly', true);
        return this.httpClient.get<Security>('/api/admin/filestack-security/platform', {
            params
        });
    }

    checkReadSecurityExpiry() {
        if ((this.readExpiry <= Date.now())) {
            this.getImageSecurityData().subscribe((securityPolicy: Security) => {
                this.initReadSecurityData(securityPolicy);
            });
        }
    }

    checkAdminReadSecurityExpiry() {
        if (this.readExpiry <= Date.now()) {
            this.getAdminImageSecurityData().subscribe((securityPolicyData: Security) => {
                this.initReadSecurityData(securityPolicyData);
            });
        }
    }

    delete(handle: string) {
        this.getUploadSecurityData(handle).subscribe((deleteSecurityData) => {
            this.filestackClient.remove(handle, deleteSecurityData);
        });
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCdnResizedImage(imagePath: string | undefined | null, width: number | undefined, height: number | undefined, fit = 'max'): string {
        if (!imagePath) {
            return '';
        }
        this.checkReadSecurityExpiry();
        if (imagePath.includes('www.filepicker.io') || imagePath.includes('sfiles.personifyhealth.com') || imagePath.includes('file.personifyhealth.com')) {
            const validatedImageUrl = imagePath.replace(('www.filepicker.io' || 'sfiles.personifyhealth.com' || 'file.personifyhealth.com'), 'sfiles.personifyhealth.com');
            return validatedImageUrl.replace('/api/file/', `/resize=fit:${fit},w:${width}/rotate=deg:exif/compress/security=p:${this.readSecurityData.policy},s:${this.readSecurityData.signature}/`);
        }
        return imagePath;
    }

    download(urls: string[], fileNames: string[]): Promise<string[]> {
        this.checkReadSecurityExpiry();
        return new Promise((resolve) => {
            const failedDownloads: string[] = [];
            urls.forEach((url: string, index: number) => {
                this.downloadClient.download(
                    this.parseFileHandleFromUrl(url),
                    this.readSecurityData
                ).then((response) => {
                    saveAs(response.data, fileNames[index]);
                    if (urls.length <= index + 1) {
                        resolve(failedDownloads);
                    }
                }).catch(() => {
                    failedDownloads.push(url);
                });
            });
        });
    }

    getTransformationOptions(): PickerTransformationOptions {
        return {
            crop: true,
            rotate: true,
            force: true
        };
    }

    private parseFileHandleFromUrl(url: string): string {
        return url.substring(url.lastIndexOf('/') + 1, url.includes('?') ? url.lastIndexOf('?') : url.length);
    }

    private getUploadSecurityData(handle?: string): Observable<Security> {
        let params = new HttpParams();
        if (handle) {
            params = params.set('handle', handle);
        }
        return this.httpClient.get<Security>(`/api/members/${this.member.id}/filestack-security/platform/write-access`, {
            params
        });
    }

    private getAdminUploadSecurityData(handle?: string): Observable<Security> {
        let params = new HttpParams();
        if (handle) {
            params = params.set('handle', handle);
        }
        return this.httpClient.get<Security>('/api/admin/filestack-security/platform/write-access', {
            params
        });
    }

    private parseJsonPolicy(securityData: Security) {

        let expiry = 0;
        try {
            expiry = JSON.parse(atob(securityData.policy)).expiry;
        } catch {
            expiry = 0;
        }

        return (expiry - this.constants.NUM_OF_SECONDS) * this.constants.NUM_OF_MS_IN_SECOND;
    }


    validateDimensions(minDimensions: number[] | null, maxDimensions: number[] | null, url: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loadImage(this.secureFilePath(url)).then((image) => {
                const isUnderMinimumDimensions = minDimensions != null ? image.width < minDimensions[0] || image.height < minDimensions[1] : false;
                const isOverMaximumDimensions = maxDimensions != null ? image.width > maxDimensions[0] || image.height > maxDimensions[1] : false;
                if (isUnderMinimumDimensions) {
                    reject('minDimensions');
                } else if (isOverMaximumDimensions) {
                    reject('maxDimensions');
                }
                image.src = url;
                resolve(image);
            });
        });
    }

    loadImage(url: string): Promise<any> {
        return new Promise((resolve) => {
            const image = new Image();
            image.src = url;
            image.onload = () => {
                resolve(image);
            };
        });
    }

    compressImageLoad(value: string, resize: number) {
        if (!value) {
            return value;
        }
        return value.replace(this.constants.URL_PATH, `/resize=fit:max,w:${resize}/rotate=deg:exif/compress/security=p:${this.readSecurityData.policy},s:${this.readSecurityData.signature}/`);
    }

    secureFilePath(value: string) {
        return value.replace(this.constants.URL_PATH, `/security=p:${this.readSecurityData.policy},s:${this.readSecurityData.signature}/`);
    }

    private getExtension(path: any) {
        const basename = path.split(/[\\/]/).pop();
        const pos = basename.lastIndexOf('.');
        if (basename === '' || pos < 1) {
            return '';
        }

        return basename.slice(pos + 1);
    }

    private initUploadSecurityData(uploadSecurityData: Security) {
        this.filestackClient = this.fileStackService.init(
            this.configurationService.getFilePickerAPI(),
            FilePickerService.getClientOptions(uploadSecurityData)
        );
    }

    private static getClientOptions(security: Security): ClientOptions {
        return { cname: undefined, forwardErrors: false, security: security, sessionCache: false } as ClientOptions;
    }

    private static validateImageUrl(image: PickerFileMetadata) {
        const pattern = 'cdn.filestackcontent.com';
        const url = 'sfiles.personifyhealth.com/api/file';
        image.url = image.url.replace(pattern, url);
        return image;
    }

    static getFilePickerReadOnlySecurityData(memberId: string, httpClient: HttpClient) {
        const sessionCache = new PersistentCache<Security>('@app:filestack-cache', 3600);

        const filePickerSecurityData = sessionCache.getItem('filePickerSecurityData');

        if (!filePickerSecurityData) {
            const params = new HttpParams().set('readOnly', true);
            httpClient
                .get<Security>(`/api/members/${memberId}/filestack-security/platform`, {
                    params
                })
                .subscribe((response) => {
                    sessionCache.setItem('filePickerSecurityData', response);
                });
        }
    }
}
