import { ReplaySubject } from 'rxjs';

import { MemberClass } from './classes';
import { Feature } from './interfaces';
import { MemberSettings } from './interfaces/member-settings.model';
import type { AppProps } from 'single-spa';

/**
 * @deprecated Will be refactored to use DI
 */
export const singleSpaPropsSubject = new ReplaySubject<SingleSpaProps>(1);

export type SingleSpaProps = AppProps & {
    authenticatedMember: MemberClass;
    authenticatedMemberService: any;
    authClient: any;
    mixpanel: any;
    microstrategyUrl: any;
    memberFeatures: Feature[];
    memberSettings: MemberSettings;
    shellAppName: string;
    filepickerService: any;
    authenticatedUser: any;
    sponsorReplaySubject: ReplaySubject<any>;
    isAuthenticatedUserAnAgent: boolean;
    environment: string;
};
