import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticatedMemberService, ConnectedDevice, extendWith } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class ConnectedDevicesService {
    authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
    defaultImage = '/img/devices/DeviceImages_Default.jpg';
    images = [{
        location: '/img/devices/DeviceImages_Max.jpg',
        type: 'SBPED'
    }, {
        location: '/img/devices/DeviceImages_Endomondo.jpg',
        type: 'ENDOM'
    }, {
        location: '/img/devices/DeviceImages_FitBit.jpg',
        type: 'FITBT'
    }, {
        location: '/img/devices/DeviceImages_Garmin.jpg',
        type: 'GRMIN'
    }, {
        location: '/img/devices/DeviceImages_Jawbone.jpg',
        type: 'JAWBN'
    }, {
        location: '/img/devices/DeviceImages_MFP.jpg',
        type: 'MFTPL'
    }, {
        location: '/img/devices/DeviceImages_Misfit.jpg',
        type: 'MISFT'
    }, {
        location: '/img/devices/DeviceImages_Moves.jpg',
        type: 'MOVES'
    }, {
        location: '/img/devices/DeviceImages_SleepTime.jpg',
        type: 'AZUMI'
    }, {
        location: '/img/devices/DeviceImages_PHApp.jpg',
        type: 'DROID'
    }, {
        location: '/img/devices/DeviceImages_PHApp.jpg',
        type: 'APPLE'
    }, {
        location: '/img/devices/DeviceImages_MaxBuzz.jpg',
        type: 'MBUZZ'
    }];

    constructor(
        private httpClient: HttpClient,
        private authenticatedMemberService: AuthenticatedMemberService
    ) {}

    getMembersConnectedDevices(): Observable<ConnectedDevice[]> {
        return new Observable((observer) => {
            const subscription = this.httpClient.get(`/api/members/${this.authenticatedMember.id}/integrations`).subscribe((response) => {
                observer.next(this.getDevices(response));
                observer.complete();
            });

            return {
                unsubscribe() {
                    subscription.unsubscribe();
                }
            };
        });
    }

    getFriendsConnectedDevices(friendId: number): Observable<ConnectedDevice[]> {
        return new Observable((observer) => {
            const subscription = this.httpClient.get(`/api/members/${this.authenticatedMember.id}/friends/${friendId}/integrations`).subscribe((response) => {
                observer.next(this.getDevices(response));
                observer.complete();
            });

            return {
                unsubscribe() {
                    subscription.unsubscribe();
                }
            };
        });
    }

    private getImage(type: string): string {
        const image = this.images
            .filter((image: any) => image.type === type)
            .map((image: any) => image.location)[0];

        return image ? image : this.defaultImage;
    }

    private getDevices(devices: any): ConnectedDevice[] {
        const tempDevices: ConnectedDevice[] = [];
        devices.forEach((device: any, index: number) => {
            tempDevices.push(extendWith(device, {
                image: this.getImage(device.DeviceType),
                orderIndex: device.DeviceType === 'SBPED' ? 0 : (index + 1),
                DeviceDescription: device.DeviceType === 'SBPED' ? 'Max' : device.DeviceDescription
            }));
        });

        return tempDevices.filter((tempDevice: any) => tempDevice.DeviceType !== 'VALDC');
    }
}
