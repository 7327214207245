export enum Links {
    HealthZone = '/HZClickOnce/HealthZoneSync.application',
    Support = '/#/zendesk/support',
    ZendeskSupport = '/zendesk/support',
    MaxShop = '/max-30',
    ShopGiftCards = '/emporium/gift-cards',
    ACHDeposit = '/#/achdeposit',
    ShopDevices = '/shop/',
    Analytics = '/api/domo-keycloak-client/sso-url',
    Inactive = '{0}/register/#/inactive',
    VirginPulse = 'virginpulse.com',
    FoodSmart = 'pif-foodsmart&scope=events&response_type=code&redirect_uri=https://personifyhealth.zipongo.com/login&state=https://personifyhealth.zipongo.com/ ',
    OAuthSSO = '/auth/realms/platform/protocol/openid-connect/auth?client_id='
}
