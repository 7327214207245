import { Injectable } from '@angular/core';

import { SingleSpaConfiguration } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class ModuleRegistry {

    modules : Record<string, SingleSpaConfiguration> = {
        footer:  {
            appName: 'footer',
            appImportPath: 'footer',
            appRegex: null,
            isStandAloneApp: true
        },

        blocker: {
            appName: 'blocker-ui',
            appImportPath: 'blocker-ui',
            appRegex: null,
            isStandAloneApp: true
        },

        notifications: {
            appName: 'notifications-ui',
            appImportPath: 'notifications-ui',
            appRegex: null,
            isStandAloneApp: true
        },

        navigationMenu:  {
            appName: 'navigation-menu',
            appImportPath: 'navigation-menu',
            appRegex: null,
            isStandAloneApp: true
        },
        messageCenter:  {
            appName: 'message-center',
            appImportPath: 'message-center',
            appRegex: null,
            isStandAloneApp: true
        },

        tpaResources:  {
            appName: 'tpa-resources',
            appImportPath: 'tpa-resources',
            appRegex: null,
            isStandAloneApp: true
        },
        messageCentre:  {
            appName: 'message-centre-ui',
            appImportPath: 'message-centre-ui',
            appRegex: null,
            isStandAloneApp: true
        }
    };
}
