import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

import { InterestModalService } from '../interest-modal/interest-modal.service';
import {
    ActionListType,
    AddMemberAllPromotedRewards,
    AnalyticsTrackingAction,
    AnalyticsTrackingSingleSpaService,
    AuthenticatedMemberService,
    FeatureEnum, FeatureService, GlobalReduxStore,
    Member,
    PalMemberPromotedAction,
    PersonalizedActionVM,
    RewardCategory,
    RewardType,
    UIState
} from '@genesis-frontend/genesis-utilities';


@Injectable({
    providedIn: 'root'
})
export class PalService {

    readonly ZERO_DISPLAY_VALUE = '0';
    readonly palInterests = 'INT';
    readonly eventCodes = ['MCCPS1', 'WLT', 'MDPLN', 'VWT'];
    public readonly memberPromotedActions$ = new ReplaySubject<PalMemberPromotedAction[]>();
    public palActions: PersonalizedActionVM[] = [];
    rewardText = '';

    constructor(
        private readonly httpClient: HttpClient,
        private readonly authenticatedMember: AuthenticatedMemberService,
        private modalService: InterestModalService,
        private featureService: FeatureService,
        private mfReduxStore: GlobalReduxStore,
        private translate: TranslateService,
        private analyticsTrackingSingleSpaService: AnalyticsTrackingSingleSpaService
    ) {
        this.featureService.getMemberFeatures().subscribe((res) => {
            this.featureService.setMemberFeatures(res);

            if (this.featureService.memberHasFeature(FeatureEnum.PAL)) {
                this.getMemberRecommendedActions().subscribe((personalizedActionVM: PersonalizedActionVM[]) => {
                    this.memberPromotedActions$.next(this.mapPersonalizedAction(personalizedActionVM));
                    this.palActions = personalizedActionVM;
                });

            } else {
                const palMemberPromotedActions = this.mfReduxStore.getInitialState().allPromotedRewards;
                if (palMemberPromotedActions) {
                    this.memberPromotedActions$.next(palMemberPromotedActions);
                    return;
                }
                this.getMemberPromotedActions().subscribe((palMemberPromotedActions: PalMemberPromotedAction[]) => {
                    this.memberPromotedActions$.next(palMemberPromotedActions);
                    this.mfReduxStore.dispatchAction(AddMemberAllPromotedRewards(palMemberPromotedActions));
                });
            }
        });
    }

    fetchActionRecommendations(): Observable<UIState<PalMemberPromotedAction[]>> {
        const memberId: number = this.authenticatedMember.getAuthenticatedMemberId();
        const params = new HttpParams().set('count', 2);
        const url = `/api/members/${memberId}/action/recommendations`;

        return this.httpClient.get<PersonalizedActionVM[]>(url, { params }).pipe(
            map((response) => ({
                loading: false,
                data: this.mapPersonalizedAction(response)
            })),
            catchError((error: Error) => of({
                loading: false,
                error
            })),
            startWith({
                loading: true
            })
        );
    }

    fetchWaysToEarn(): Observable<UIState<PalMemberPromotedAction[]>> {
        const member: Member = this.authenticatedMember.getAuthenticatedMember();
        const memberRewardableFeatures = this.authenticatedMember.getRewardableFeatures();
        const params = new HttpParams().set('memberFeatures', memberRewardableFeatures.toString());
        const url = `/rewards-api/public/sponsors/${member.sponsorId}/members/${member.id}/ways-to-earn`;

        return this.httpClient.get<PalMemberPromotedAction[]>(url, { params }).pipe(
            map((response) => ({
                loading: false,
                data: response
            })),
            catchError((error: Error) => of({
                loading: false,
                error
            })),
            startWith({
                loading: true
            })
        );
    }

    handleCardClick(card: PalMemberPromotedAction, analyticsIndex: number): void {
        if (!card.url) {
            return;
        }
        const noRewardAction = card.rewardType === RewardType.NoReward;

        if (this.hasEventCode(card.eventCode) && noRewardAction) {
            this.putMemberRecommendedAction(analyticsIndex).subscribe();
        }

        if (card.eventCode === this.palInterests) {
            this.modalService.openModal();
            return;
        }

        this.handleUrlRedirection(card.url);
        this.trackActionListItemClick(card, analyticsIndex);
    }

    private trackActionListItemClick(actionItem: PalMemberPromotedAction, analyticsIndex: number): void {
        this.analyticsTrackingSingleSpaService.sendData(AnalyticsTrackingAction.ActionListItemClicked, {
            action_item_name: actionItem.name,
            action_item_id: actionItem.rewardableActionId,
            action_rewardable_type: actionItem.rewardType,
            action_interval_type: actionItem.intervalType,
            action_item_category_name: actionItem.category,
            action_item_event_code: actionItem.eventCode,
            action_url_link: actionItem.url,
            action_reward_type: actionItem.rewardType,
            action_item_action_space_name: actionItem.actionSpace,
            action_rewardable: actionItem.rewardType !== RewardType.NoReward,
            action_location: 'DFD',
            action_space_index: analyticsIndex + 1,
            action_list_type: actionItem.actionListType ? ActionListType.PAL : ActionListType.Original
        });
    }

    getMemberRecommendedActions(): Observable<PersonalizedActionVM[]> {
        const memberId: number = this.authenticatedMember.getAuthenticatedMemberId();
        const url = `api/members/${memberId}/action/recommendations`;

        return this.httpClient.get<PersonalizedActionVM[]>(url);
    }

    private getMemberPromotedActions(): Observable<PalMemberPromotedAction[]> {
        const member: Member = this.authenticatedMember.getAuthenticatedMember();
        const memberRewardableFeatures = this.authenticatedMember.getRewardableFeatures();
        const params = new HttpParams().set('memberFeatures', memberRewardableFeatures.toString());
        const url = `/rewards-api/public/sponsors/${member.sponsorId}/members/${member.id}/ways-to-earn`;

        return this.httpClient.get<PalMemberPromotedAction[]>(url, { params });
    }

    putMemberRecommendedAction(index: number): Observable<PersonalizedActionVM> {
        const memberId: number = this.authenticatedMember.getAuthenticatedMemberId();
        const url = `api/members/${memberId}/action/recommendations`;
        this.palActions[index].uiClicked = true;
        this.palActions[index].dateClicked = new Date();
        return this.httpClient.put<PersonalizedActionVM>(url, this.palActions);
    }

    generateTitle(item: PersonalizedActionVM): string {
        return `${item.title}${this.getRewardTimesInfo(item)}`;
    }

    generateAriaLabel(item: PersonalizedActionVM): string {
        const completed = item.completionCondition.completed;
        const completionStatus = this.translate.instant(completed ? 'Complete' : 'Incomplete');
        return `${item.title}${this.getRewardTimesInfo(item)}${this.getPoints()}, ${completionStatus}`;
    }

    private mapPersonalizedAction(personalizedActionVM: PersonalizedActionVM[]): PalMemberPromotedAction[] {
        return personalizedActionVM.map((actionVM) => {
            const title = this.generateTitle(actionVM);
            this.rewardText = this.getRewardText(actionVM);
            const ariaLabel = this.generateAriaLabel(actionVM);
            const role = this.generateRole(actionVM.webLink);

            return {
                id: actionVM.id,
                rewardableActionId: actionVM.actionId,
                name: title,
                rewardType: actionVM.reward?.rewardType || RewardType.NoReward,
                rewardTypeDisplay: actionVM.reward?.rewardType,
                rewardValue: actionVM.reward?.value,
                rewardDisplayValue: actionVM.reward?.value.toString(),
                url: actionVM.webLink,
                category: RewardCategory.Activity,
                eventCode: actionVM.rewardEventCode,
                intervalType: actionVM.intervalType,
                timesEarned: actionVM.completionCondition.timesEarned,
                timesRewardable: actionVM.completionCondition.timesRewardable,
                completed: actionVM.completionCondition.completed,
                actionSpace: actionVM.actionSpace,
                actionListType: ActionListType.PAL,
                shouldDisplay: actionVM.shouldDisplay,
                rewardText: this.rewardText,
                iconSource: this.getIconSource(actionVM),
                ariaLabel: ariaLabel,
                role: role
            } as PalMemberPromotedAction;
        });
    }

    generateRole(url: string | null): string {
        if (!url) {
            return '';
        }
        return url.includes('openMyInterests') ? 'button' : 'link';
    }

    private getRewardText({ reward }: PersonalizedActionVM): string {
        const rewardType = reward?.rewardType || RewardType.NoReward;
        const rewardDisplayValue = reward?.value.toString();
        const rewardTypeDisplay = reward?.rewardType;

        if (rewardType === RewardType.NoReward) {
            return '';
        }
        if (rewardDisplayValue === this.ZERO_DISPLAY_VALUE) {
            return rewardTypeDisplay as string;
        }

        return `${rewardDisplayValue} ${rewardTypeDisplay}`;
    }

    private getIconSource({ reward, shouldDisplay }: PersonalizedActionVM): string {
        const rewardType = reward?.rewardType || RewardType.NoReward;
        const rewardDisplayValue = reward?.value.toString();

        if (rewardType !== RewardType.NoReward && rewardDisplayValue !== this.ZERO_DISPLAY_VALUE && shouldDisplay) {
            return rewardType === RewardType.PulseCash
                ? '/img/rewards-cash-icon.svg'
                : '/img/rewards-star-icon.svg';
        }
        return '';
    }

    private handleUrlRedirection(url: string): void {
        url = url.replace(/\/profile$/, 'member-profile')
            .replace(/myfriends/, 'addfriends');

        if (this.isInternalUrl(url)) {
            window.location.href = url;
        } else {
            window.open(url, '_blank');
        }
    }

    private isInternalUrl(url: string): boolean {
        return url.startsWith('https://app.');
    }

    private hasEventCode(cardEventCode: string): boolean {
        return this.eventCodes.includes(cardEventCode);
    }

    private isItemRewardable(item: PersonalizedActionVM): boolean {
        const { timesRewardable, timesEarned } = item.completionCondition;
        return timesRewardable > 1 && timesEarned > 0;
    }

    private getRewardTimesInfo(item: PersonalizedActionVM): string {
        const { timesEarned, timesRewardable } = item.completionCondition;
        return this.isItemRewardable(item) ? ` (${timesEarned} / ${timesRewardable})` : '';
    }

    private getPoints(): string {
        return this.rewardText ? `, ${this.rewardText}` : '';
    }
}
