export enum ActionSpace {
    Benefits = 'Benefits',
    Coaching = 'Coaching',
    CorporateChallenges = 'Corporate Challenges',
    DailyCards = 'Daily Cards',
    Devices = 'Devices',
    HealthyHabits = 'Healthy Habits',
    Journeys = 'Journeys',
    MyCareChecklist = 'My Care Checklist',
    Navigate = 'Navigate',
    Profile = 'Profile',
    SocialPage = 'Social Page',
    Stats = 'Stats',
    Surveys = 'Surveys',
    SponsorSelected = 'SponsorSelected'
}
