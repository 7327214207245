export enum ClientRoles {
        RootAdmin = 'ROLE_RootAdmin',
        SurveyAdmin = 'ROLE_SurveyAdmin',
        SurveyViewer = 'ROLE_SurveyViewer',
        ClientSupportAgent = 'ROLE_ClientSupportAgent',
        CalendarSponsorAdmin = 'ROLE_CalendarSponsorAdmin',
        SponsorSuperAdmin = 'ROLE_SponsorSuperAdministrator',
        SponsorAdmin = 'ROLE_SponsorAdministrator',
        CalendarAdmin = 'ROLE_CalendarAdmin',
        AgentAdminBasicInfo = 'ROLE_AgentAdmin_BasicInfoPage',
        AgentAdminRewards = 'ROLE_AgentAdmin_RewardsPage',
        AgentAdminRedemption = 'ROLE_AgentAdmin_RedemptionPage',
        AgentAdminRewardableActions = 'ROLE_AgentAdmin_RewardableActionPage',
        AgentAdminMemberEligible = 'ROLE_AgentAdmin_MemberEligible',
        HabitsAdmin = 'ROLE_HabitsAdmin',
        HabitsViewer = 'ROLE_HabitsViewer',
        CardAdmin = 'ROLE_CardAdmin',
        CardViewer = 'ROLE_CardViewer',
        GroupSubmissionFormAdmin = 'ROLE_GroupSubmissionFormAdmin',
        GroupBoardAdmin = 'ROLE_GroupBoardAdmin',
        PlatformAnalyticsAdministrator = 'ROLE_PlatformAnalyticsAdministrator',
        ClaimsDashboardAdmin = 'ROLE_ClaimsDashboardAdmin',
        NavigateDashboardAdmin = 'ROLE_NavigateDashboardAdmin',
        ExtractCatalogAdmin = 'ROLE_ExtractCatalogAdmin',
        PepsiCoChallengeDashboardAdmin = 'ROLE_PepsiCoChallengeDashboardAdmin',
        TransformDashboardAdmin = 'ROLE_TransformDashboardAdmin',
        PopulationRiskDashboardAdmin = 'ROLE_PopulationRiskDashboardAdmin',
        PartnerReportingAdministrator = 'ROLE_PartnerReportingAdministrator',
        ReportingAdmin = 'ROLE_ReportingAdmin',
        SponsorViewer = 'ROLE_SponsorViewer',
        TechnicalSupport = 'ROLE_TechnicalSupport',
        ChallengeViewer = 'ROLE_ChallengeViewer',
        ChallengeAdmin = 'ROLE_ChallengeAdmin',
        ChallengeAdminWithoutPII = 'ROLE_ChallengeAdminWithoutPII',
        ShoutoutsAdmin = 'ROLE_ShoutoutsAdmin',
        AgentAdminSubmitRedemptionTransaction = 'ROLE_AgentAdmin_SubmitRedemptionTransaction',
        AgentAdminTransactionCredentialsViewer = 'ROLE_AgentAdmin_TransactionCredentialsViewer',
        AgentAdminViewPHI = 'ROLE_AgentAdmin_ViewPHI',
        AgentAdminRewardsViewOnly = 'ROLE_AgentAdmin_RewardsViewOnly',
        TPAAdminGroupEnrollment = 'ROLE_TPAAdminGroupEnrollment',
        TPASuperAdmin = 'ROLE_TPASuperAdmin',
        TPAAdminClaimsEob = 'ROLE_TPAAdminClaimsEOB',
        TPABrokerAdminPHI = 'ROLE_TPABrokerAdminPHI',
        TPABrokerAdminNoPHI = 'ROLE_TPABrokerAdminNoPHI',
        HealthNotesAdmin = 'ROLE_HNAdmin'
}
