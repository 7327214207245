import { Inject, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { ResourceCenterService } from './resource-center.service';
import { RoleAuthorizationService } from './role-authorization.service';
import { SelectedSponsorService } from './selected-sponsor.service';
import { SystemContentService } from './system-content.service';
import { ShellApplication, SHELL_APPLICATION } from '../../core/shell-application';
import { assign, findIndex } from '../../lib/lodash';
import { MemberRole } from '../../models/enums';
import { ClientRoles } from '../enums';
import { Member } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class LeftNavigationService {

    navItemsBadgeMap: any = [];
    menuItemsMap: any = [];
    selectedSponsorId!: number;
    private selectedSponsorSubscription: Subscription;


    constructor(
       private roleAuthorization: RoleAuthorizationService,
       private systemContentService: SystemContentService,
       private resourceCenterService: ResourceCenterService,
       private selectedSponsorService: SelectedSponsorService,
       @Inject(SHELL_APPLICATION) private shellApplication: ShellApplication
    ) {
        this.selectedSponsorSubscription = this.shellApplication.sponsorReplaySubject.subscribe((sponsor) => {
            this.selectedSponsorId = sponsor.id;
        });
    }

    setBadgeCount(state: any, count: any) {
        let existingBadge = this.getBadgesCount(state);

        if (existingBadge) {
            this.navItemsBadgeMap[state] = count;
        } else {
            existingBadge = {};
            existingBadge[state] = count;
            this.navItemsBadgeMap = assign(this.navItemsBadgeMap, existingBadge);
        }
    }

    getMenuItems(member: any) {
        if (!this.menuItemsMap[this.selectedSponsorId]) {
            this.initMenuItems(member);
        }

        return this.menuItemsMap[this.selectedSponsorId];
    }

    getBadgesCount(state: any) {
        return this.navItemsBadgeMap[state];
    }

    decreaseBadgeCount(state: any) {
        const currentCount = this.getBadgesCount(state);
        if (currentCount) {
            this.setBadgeCount(state, currentCount - 1);
        }
    }

    initMenuItems(member: any) {
        if (this.menuItemsMap[this.selectedSponsorId]) {
            delete this.menuItemsMap[this.selectedSponsorId];
        }

        if (!(this.roleAuthorization.hasIdenticalRolePermission(member.roles, [ClientRoles.HealthNotesAdmin])
            || this.roleAuthorization.hasIdenticalRolePermission(member.roles, [ClientRoles.HealthNotesAdmin, MemberRole.MemberServices]))) {
            this.menuItemsMap[this.selectedSponsorId] = this.getBaseMenuItems();
            this.appendSystemContentPendingSublist(member);

            this.addAnnouncementsNavItem(member);
            this.checkSponsorSettings(member);
        } else {
            this.menuItemsMap[this.selectedSponsorId] = this.getHealthNotesBaseMenuItems();
        }
    }

    appendSystemContentPendingSublist(member: any) {
        const systemContentState = 'systemContent.list';
        const needsApprovalSub = {
            text: 'Needs Approval',
            showBadge: false,
            state: 'systemContent.pendingList',
            title: 'Needs Approval',
            isHidden: false
        };

        const systemContentMenuIndex = findIndex(this.menuItemsMap[this.selectedSponsorId], {
            state: systemContentState
        });

        if (systemContentMenuIndex < 0
            || !this.roleAuthorization.canViewPage(systemContentState, member)) {
            return;
        }

        needsApprovalSub.isHidden = true;

        this.systemContentService
            .getSponsorSystemContentCount(this.selectedSponsorId)
            .subscribe((contentCount: any) => {
                if (contentCount.value > 0) {
                    this.setBadgeCount(needsApprovalSub.state, contentCount.value);
                    needsApprovalSub.showBadge = true;
                    needsApprovalSub.isHidden = false;
                }

                this.menuItemsMap[this.selectedSponsorId][systemContentMenuIndex].subMenu.push(needsApprovalSub);
            });
    }

    addAnnouncementsNavItem(member: any) {
        const announcements = 'Announcements';
        const content = 'content';
        const announcementsNavItem = {
            state: 'announcement.list',
            text: announcements,
            title: announcements
        };

        const customContentMenuIndex = findIndex(this.menuItemsMap[this.selectedSponsorId], {
            state: content
        });

        if (customContentMenuIndex < 0
            || !this.roleAuthorization.canViewPage(announcementsNavItem.state, member)) {
            return;
        }

        this.menuItemsMap[this.selectedSponsorId][customContentMenuIndex].subMenu.push(announcementsNavItem);
    }

    addResourceCenterNavItem(member: any) {
        const resourceCenterNavItem = {
            text: 'Resource Center',
            state: 'resource-center',
            title: 'Resource Center',
            icon: 'resource'
        };

        const reportArchiveIndex = findIndex(this.menuItemsMap[this.selectedSponsorId], { state: 'reportArchive' });

        if (reportArchiveIndex < 0
            || !this.roleAuthorization.canViewPage(resourceCenterNavItem.state, member)) {
            return;
        }
        this.menuItemsMap[this.selectedSponsorId].splice(reportArchiveIndex + 1, 0, resourceCenterNavItem);
    }

    checkSponsorSettings(member: Member) {
        this.selectedSponsorService.getSponsorSettings(this.selectedSponsorId)
            .subscribe((response: any) => {
                this.isResourceCenterEnabled(member, response);
                this.isNavigateEnabled(member, response);
            });
    }

    isResourceCenterEnabled(member: Member, sponsorSettings: any) {
        const distributorId = sponsorSettings.security.sponsor.distributorId;
        if (distributorId) {
            this.resourceCenterService.getResourceCenterAvailability(distributorId)
                .subscribe((response: any) => {
                    if (response.resourceCenterEnabled) {
                        this.addResourceCenterNavItem(member);
                    }
                });
        }
    }

    isNavigateEnabled(member: Member, sponsorSettings: any) {
        this.selectedSponsorService.getBenefitSponsorSettings(this.selectedSponsorId).subscribe(() => {
            // this.updateBenefitsClientAdminNavItems(member, benefitsSettings.hasNavigate);
            this.updateBenefitsClientAdminNavItems(member, sponsorSettings.enableVpNavigate);
        });
    }

    updateBenefitsClientAdminNavItems(member: Member, navigateEnabled: boolean) {
        const benefitsClient = 'benefits-client';

        const benefitsNavItemIndex = findIndex(this.menuItemsMap[this.selectedSponsorId], {
            state: benefitsClient
        });
        const customPagesNavItem = {
            text: 'Custom Format',
            state: 'benefits-client/custom-pages',
            title: 'Custom Format'
        };

        if (benefitsNavItemIndex >= 0 && this.roleAuthorization.canViewPage(benefitsClient, member)) {
            if (navigateEnabled) {
                this.menuItemsMap[this.selectedSponsorId][benefitsNavItemIndex].subMenu.push(customPagesNavItem);
            }
        } else {
            delete this.menuItemsMap[this.selectedSponsorId][benefitsNavItemIndex];
        }
    }

    getBaseMenuItems() {
        return [{
            text: 'Calendar / Events',
            state: 'calendar.home',
            title: 'Calendar / Events',
            icon: 'calendar'
        }, {
            text: 'Benefits',
            state: 'benefits-client',
            title: 'Benefits',
            icon: 'benefits',
            subMenu: [{
                text: 'Standard Format',
                state: 'benefits-client/benefit-program',
                title: 'Standard Format'
            }]
        }, {
            text: 'Challenges',
            state: 'challenges',
            title: 'Challenges',
            icon: 'challenges'
        }, {
            text: 'Surveys',
            state: 'surveys',
            title: 'surveys',
            icon: 'surveys'
        }, {
            text: 'Reporting & Analytics',
            state: 'enhanced-analytics',
            title: 'Reporting & Analytics',
            icon: 'enhanced-analytics',
            subMenu: [{
                text: 'Program Performance',
                state: 'program-performance',
                title: 'Program Performance',
                subMenu: [{
                    text: 'Engage & Enroll',
                    state: 'engage-enroll',
                    title: 'Engage & Enroll'
                },
                {
                    text: 'Incentives',
                    state: 'incentives',
                    title: 'Incentives'
                },
                {
                    text: 'Login Activity',
                    state: 'login-activity',
                    title: 'Login Activity'
                },
                {
                    text: 'Activity Tracking',
                    state: 'activity-tracking',
                    title: 'Activity Tracking'
                },
                {
                    text: 'Holistic Challenges',
                    state: 'holistic-challenges',
                    title: 'Holistic Challenges'
                }
                ]
            },
            {
                text: 'Population Health',
                state: 'population-health',
                title: 'Population Health',
                subMenu: [{
                    text: 'Health Check',
                    state: 'health-check',
                    title: 'Health Check'
                },
                {
                    text: 'Biometrics',
                    state: 'biometrics',
                    title: 'Biometrics'
                },
                {
                    text: 'Gaps In care',
                    state: 'gaps-in-care',
                    title: 'Gaps In Care'
                },
                {
                    text: 'Claims',
                    state: 'claims-dashboard',
                    title: 'Claims'
                },
                {
                    text: 'Population Risk',
                    state: 'population-risk',
                    title: 'Population Risk'
                }]
            },
            {
                text: 'Point Solutions',
                state: 'point-solutions',
                title: 'Point Solutions',
                subMenu: [{
                    text: 'Partners',
                    state: 'partners',
                    title: 'Partners'
                },
                {
                    text: 'Coaching',
                    state: 'coaching-dashboard',
                    title: 'Coaching'
                },
                {
                    text: 'Navigate',
                    state: 'navigate-dashboard',
                    title: 'Navigate'
                },
                {
                    text: 'Transform',
                    state: 'transform',
                    title: 'Transform'
                }]
            },
            {
                text: 'Extract Catalog ',
                state: 'extract-catalog',
                title: 'Extract Catalog'
            },
            {
                text: 'PepsiCo Challenge Dashboard',
                state: 'pepsico',
                title: 'PepsiCo Challenge Dashboard'
            }
            ]
        }, {
            text: 'Data Hub',
            state: 'data-hub',
            title: 'Data Hub',
            icon: 'report'
        }, {
            text: 'Coaching',
            state: 'coaching',
            title: 'Coaching',
            icon: 'coaching'
        }, {
            text: 'Members',
            state: 'agent-admin',
            title: 'Members',
            icon: 'members'
        }, {
            text: 'Eligible members',
            state: 'agent-admin-eligibles',
            title: 'Eligibles',
            icon: 'eligibles'
        },
        {
            text: 'Agent Management',
            state: 'agent-management',
            title: 'Agent Management',
            icon: 'agents'
        },
        {
            text: 'Custom Content',
            state: 'content',
            title: 'custom-content',
            icon: 'content',
            notClickable: true,
            subMenu: [{
                text: 'Habits',
                state: 'habits',
                title: 'Habits'
            }, {
                text: 'Daily Tips',
                state: 'cards',
                title: 'Cards'
            },
            {
                text: 'Email Campaigns',
                state: 'email-campaigns',
                title: 'Email Campaigns'
            }]
        }, {
            text: 'System Content',
            state: 'systemContent.list',
            title: 'content',
            icon: 'content',
            subMenu: []
        }, {
            text: 'Pillars & Topics',
            state: 'pillars',
            title: 'Pillars & Topics',
            icon: 'pillars'
        }, {
            text: 'Branding',
            state: 'branding',
            title: 'branding',
            icon: 'branding',
            notClickable: true,
            subMenu: [
                {
                    text: 'Logo',
                    state: 'logo',
                    title: 'logo'
                }, {
                    text: 'Colors',
                    state: 'colors',
                    title: 'colors'
                }
            ]
        }, {
            text: 'Shoutouts',
            state: 'recognitions',
            title: 'Shoutouts',
            icon: 'recognition'
        }, {
            text: 'Permissions',
            state: 'permissions',
            title: 'Permissions',
            icon: 'admin',
            subMenu: [{
                text: 'Analytics',
                state: 'analyticusers.list',
                title: 'Analytics'
            },
            {
                text: 'Platform',
                state: 'provisioning',
                title: 'Platform'
            },
            {
                text: 'Distributor',
                state: 'distributor',
                title: 'Distributor'
            }
            ]
        }, {
            text: 'Report Archive',
            state: 'reportArchive',
            title: 'Report Archive',
            icon: 'report'
        }, {
            text: 'Audiences',
            state: 'audiences',
            title: 'Audiences',
            icon: 'audiences'
        }, {
            text: 'Downloads',
            state: 'downloads',
            title: 'Downloads',
            icon: 'downloads'
        }, {
            text: 'Resources',
            state: 'resources',
            title: 'tpa-resources',
            icon: 'benefits',
            notClickable: true,
            subMenu: [{
                text: 'forms',
                state: 'tpa-resources',
                title: 'tpa-resources'
            },
            {
                text: 'ID Card Requests',
                state: 'tpa-resources/id-card-requests',
                title: 'ID Card Requests'
            }]
        },
        {
            text: 'Appian portal',
            state: 'appian',
            title: 'appian',
            icon: 'agents'
        }];
    }

    getHealthNotesBaseMenuItems() {
        return [{
            text: 'Healthnotes',
            state: 'health-notes.home',
            title: 'Healthnotes',
            icon: 'benefits'
        }, {
            text: 'Member Search',
            state: 'health-notes.search',
            title: 'Member Search',
            icon: 'report'
        }];
    }

    unsubscribeSelectedSponsorSubscription() {
        this.selectedSponsorSubscription.unsubscribe();
    }
}
