import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';

import { SingleSpaConfiguration } from '@genesis-frontend/genesis-utilities';

@Injectable({ providedIn: 'root' })
export class DownloadSyncResolverService implements Resolve<SingleSpaConfiguration> {
    constructor(
        private router: Router
    ) {}
    resolve(): any {
        this.router.navigateByUrl('devices/download-sync');
    }
}
